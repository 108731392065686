import { useEffect } from 'react';
import { toastText } from 'utils/utils';

const Notification = () => {
	useEffect(() => {
		const ws = new WebSocket('ws://localhost:8081');

		ws.onmessage = (event: MessageEvent) => {
			// Display the message as a toast
			toastText(event.data, 'success');
		};

		return () => {
			ws.close();
		};
	}, []);

	return null;
};

export default Notification;
