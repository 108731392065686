import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toastText } from 'utils/utils';
import { postApi } from 'apis';
import styles from './index.module.scss';
import { Spin } from 'antd';

const CallbackComponent = () => {
	const [isLoading, setIsLoading] = useState(false);

	const location = useLocation();
	const navigate = useNavigate();

	const handleApiCall = async (apiEndpoint: any, data: any) => {
		try {
			const response = await postApi(apiEndpoint, data);
			if (response.data.responseStatus === 200) {
				toastText(response.data.message, 'success');
			} else {
				// Throw an error if the API call is not successful
				throw new Error('Fail to Connect');
			}
		} catch (error: any) {
			if (error?.response?.data?.responseStatus === 500) {
				toastText('Fail to connect', 'error');
			} else {
				toastText(error?.response?.data?.message, 'error');
			}
		} finally {
			navigate('/settings/integrations');
		}
	};

	useEffect(() => {
		const run = async () => {
			setIsLoading(true);
			const url = window.location.href;
			if (location.pathname.startsWith('/qbo-callback')) {
				await handleApiCall('/qbo/callback', { url });
			}
			setIsLoading(false);
		};

		run();
	}, []);

	return (
		<div className={styles['main-container']}>
			{isLoading && (
				<div
					style={{
						width: '100%',
						minHeight: '200px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Spin />
				</div>
				// <img src={`/assets/gifs/loading-black.gif`} height={60} />
			)}
		</div>
	);
};

export default CallbackComponent;
