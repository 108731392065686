// Import necessary libraries/components
import { Button, DatePicker, Select, Table, Popconfirm } from 'antd';
import { putApi } from 'apis';
import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toastText } from 'utils/utils';

const { Column } = Table;

interface Props {
	newProducts: any;
	getSalesRep: any;
	changeNewProducts: any;
	getNewProducts: () => void;
}

const NewProductTable = (props: Props) => {
	const { newProducts, getSalesRep, getNewProducts } = props;

	const [updateData, setUpdateData] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isDataChanged, setIsDataChanged] = useState(false);
	const [missingDataRows, setMissingDataRows] = useState<string[]>([]);

	const addProduct = async () => {
		setIsLoading(true);
		try {
			if (isDataChanged) {
				if (missingDataRows.length === 0) {
					const productData = updateData.map((product: any) => ({
						productId: product.id,
						applicableDate: product.applicableDate,
						salesRepId: product.salesRepId,
					}));

					const response = await putApi('/products/new', {
						productsData: productData,
					});
					setIsDataChanged(false);
					toastText(response.data?.message, 'success');
					getNewProducts();
				} else {
					toastText(
						'Please assign sales rep and applicable date',
						'error'
					);
				}
			} else {
				if (missingDataRows.length > 0) {
					toastText(
						'Please assign sales rep and applicable date',
						'error'
					);
				}
			}
		} catch (error: any) {
			toastText(error.response.data?.message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const changeProductHandler = (
		name: string,
		productId: string,
		value: any
	) => {
		const updatedData = updateData.map((product: any) => {
			if (product.id === productId) {
				return {
					...product,
					[name === 'date' ? 'applicableDate' : 'salesRepId']: value,
				};
			}
			return product;
		});
		setIsDataChanged(true);
		setUpdateData(updatedData);
	};

	const handleOk = async () => {
		setIsLoading(true);
		await addProduct();
	};

	const handleCancel = () => {
		console.log('first cancel');
		// setOpen(false);
	};

	useEffect(() => {
		if (newProducts.length > 0) {
			setUpdateData(newProducts);
		}
	}, [newProducts]);

	useEffect(() => {
		const missingRows: string[] = [];
		updateData.forEach((product: any) => {
			if (!product.salesRepId || !product.applicableDate) {
				missingRows.push(product.id);
			}
		});
		setMissingDataRows(missingRows);
	}, [updateData]);

	return (
		<div>
			<Table
				dataSource={newProducts}
				pagination={false}
				scroll={{ y: 'calc(100vh - 420px)' }}
				rowKey={(record: any) => record.id}
			>
				{/* Render columns */}
				<Column
					title="No."
					dataIndex="sr"
					key="sr"
					className="bg-white"
					render={(value, data, index) => `${index + 1}`}
					width={80}
				/>
				<Column
					title="Product Name"
					dataIndex="name"
					key="name"
					className="bg-white"
					render={(value, data: any) => {
						return <p>{value ? value : data?.name}</p>;
					}}
				/>
				<Column
					title="Product/SKUs"
					dataIndex="sku"
					key="sku"
					className="bg-white"
					render={(value: any, data: any) => (
						<p>{value ? value : data.name}</p>
					)}
				/>
				<Column
					title="Sales Rep"
					dataIndex="salesRepId"
					key="salesRepId"
					className="bg-white"
					render={(value: any, data: any) => (
						<Select
							showSearch
							style={{
								width: 220,
							}}
							placeholder="Select Sales Rep"
							optionFilterProp="label"
							defaultValue={value}
							options={getSalesRep.map((salesRep: any) => ({
								label: `${salesRep.firstName} ${salesRep.lastName}`,
								value: salesRep.id,
							}))}
							onChange={(value: string) => {
								changeProductHandler(
									'salesRep',
									data.id,
									value
								);
							}}
							status={
								missingDataRows.includes(data.id) ? 'error' : ''
							}
						/>
					)}
				/>

				<Column
					title="Applicable From Date"
					dataIndex="applicableDate"
					key="applicableDate"
					className="bg-white"
					render={(value: any, data: any) => (
						<DatePicker
							format="MM/DD/YYYY"
							onChange={(date: any) => {
								changeProductHandler('date', data.id, date);
							}}
							defaultValue={value ? dayjs(value) : null}
							disabledDate={(current) =>
								current && current < moment().startOf('day')
							}
							style={{
								borderColor: missingDataRows.includes(data.id)
									? 'red'
									: undefined,
							}}
						/>
					)}
				/>
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					className="bg-white"
					render={(value: any, data: any) => (
						<span
							style={{
								color: data.applicableDate ? 'green' : '',
							}}
						>
							{data.applicableDate ? 'Active' : 'Pending'}
						</span>
					)}
					width={120}
				/>
			</Table>
			{/* Render buttons */}

			<Popconfirm
				placement="topLeft"
				title="Changes to new product may impact commission calculations."
				description={false}
				okText="Save"
				cancelText="Cancel"
				onConfirm={handleOk}
				onCancel={handleCancel}
				okButtonProps={{ loading: isLoading }}
			>
				<Button
					className="primary-button"
					size="large"
					style={{ marginTop: '2rem', width: '10rem' }}
				>
					Save
				</Button>
			</Popconfirm>
			{/* <Buttons
				buttons={[
					{
						text: 'Save',
						isLoading: isLoading,
						className: 'primary-button',
						fontSize: '1.7rem',
						minWidth: '20rem',
						minHeight: '4rem',
						styles: { marginTop: '2rem' },
						isSubmit: false,
						onclick: () => addProduct(),
					},
				]}
				gap="large"
			/> */}
		</div>
	);
};

// Export the component
export default NewProductTable;
