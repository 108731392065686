import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { getAllSalesRepsAction } from '../../redux/actions/salesRepsAction';
import SalesReportTable from './Table';

export const SalesReport = () => {
	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		dispatch(getAllSalesRepsAction());
	}, []);

	return (
		<div>
			<SalesReportTable />
		</div>
	);
};
