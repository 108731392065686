import { CloseOutlined } from '@ant-design/icons';
import { Modal, Row } from 'antd';
import styles from './index.module.scss';
import Title from 'antd/es/typography/Title';
import './index.scss';

interface Props {
	isOpen: boolean;
	handleCancel: () => void;
	detailedData: any;
	selectedSalesRep: any;
}

const DetailCommissionModal = (props: Props) => {
	const { isOpen, handleCancel, detailedData, selectedSalesRep } = props;

	return (
		<Modal
			open={isOpen}
			closable={false}
			onCancel={handleCancel}
			className="salesrep__popup"
			footer={() => {
				return (
					<div>
						<Title level={5} className={styles['modal-footer']}>
							<span>Total Commission Paid Out</span>
							<span className={`${styles['total']} text-color`}>
								${detailedData.payoutsAmount}
							</span>
						</Title>
					</div>
				);
			}}
			width={600}
		>
			<Row className={styles['modal-header']}>
				<Title
					level={4}
					style={{
						marginBottom: '0px',
					}}
				>
					Sales Rep : {selectedSalesRep}
				</Title>
				<div className="pointer">
					<CloseOutlined
						className={styles['modal-header_close']}
						onClick={handleCancel}
						style={{
							fontSize: '2rem',
							fontWeight: '500',
						}}
					/>
				</div>
			</Row>
			<div className={styles['modal-body']}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: '5px',
					}}
				>
					<Title
						level={5}
						style={{
							marginBottom: '0px',
						}}
					>
						Commissions
					</Title>
					<> </>
					<span className={styles['note']}>
						(The commission has been calculated with fixed fees
						applied.)
					</span>
				</div>
				{detailedData.commissionType === 'Inside Commission' && (
					<div className={styles['modal-body_plan']}>
						<p>
							Inside Commission ({detailedData.commission}
							%)
						</p>
						<p>${detailedData.commissionAmount}</p>
					</div>
				)}
				{detailedData.commissionType === 'Outside Commission' && (
					<div>
						<div className={styles['modal-body_plan']}>
							<p>
								Outside Commission ({detailedData.commission}
								%)
							</p>
							<p>${detailedData.modalCommissionAmount}</p>
						</div>
						{/* <div className={styles['modal-body_plan']}>
								<p>
									Pre-Floor-Adjusted Outside Commission (
									{detailedData.outsideAdjustPercentage}
									%) =
								</p>
								<p>${detailedData.outsideAdjust}</p>
							</div> */}
					</div>
				)}

				{detailedData.commissionType ===
					'Customer Retention Commission' && (
					<div className={styles['modal-body_plan']}>
						<p>
							Customer Retention Commission (
							{detailedData.commission}
							%)
						</p>
						<p>${detailedData.commissionAmount}</p>
					</div>
				)}
				{detailedData.commissionType === 'New Product Commission' && (
					<div className={styles['modal-body_plan']}>
						<p>
							New Product Commission ({detailedData.commission}
							%)
						</p>
						<p>${detailedData.commissionAmount}</p>
					</div>
				)}
				{detailedData.commissionType ===
					'Special/Intermediate Commission' && (
					<div className={styles['modal-body_plan']}>
						<p>
							Special/Intermediate Commission (
							{detailedData.commission}
							%)
						</p>
						<p>${detailedData.commissionAmount}</p>
					</div>
				)}

				<div className={styles['modal-body_plan']}>
					<p> Standard Deductions</p>
					<p>${detailedData.deductions}</p>
				</div>
			</div>
		</Modal>
	);
};

export default DetailCommissionModal;
