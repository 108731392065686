import { Sidebar } from 'components/Global';

import { FC } from 'react';
import styles from './index.module.scss';
import { SettingsLayoutProps } from './types';
import { FORMDATA } from 'constants/Data';

// Settings page layout
const SettingsLayout: FC<SettingsLayoutProps> = (props) => {
	const { settingsMenuItems } = FORMDATA;
	const { children, onSideBarChange, selectedSidebar } = props;

	// JSX
	return (
		<div className={styles['settings-layout']}>
			<div className={styles['settings-layout__wrapper']}>
				<Sidebar
					items={settingsMenuItems}
					handleSidebar={onSideBarChange}
					selectedSidebar={selectedSidebar as string}
				/>
				<div className={styles['settings-layout__body']}>
					{children}
				</div>
			</div>
		</div>
	);
};

export default SettingsLayout;
