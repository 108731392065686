// import { Select, Tooltip } from 'antd';
// import React, { useState } from 'react';
// import styles from './index.module.scss';
// import { DownloadOutlined } from '@ant-design/icons';

// interface Props {
// 	handleExport: (type: string) => void;
// 	isLoading: boolean;
// }

// const Export = (props: Props) => {
// 	const { isLoading, handleExport } = props;

// 	const [type, setType] = useState('');

// 	const handleChange = (value: string) => {
// 		setType(value);
// 	};

// 	const submitHandler = () => {
// 		handleExport(type);
// 	};

// 	return (
// 		<div className={`${styles['export-data']}`}>
// 			<div className={styles['export-data__wrapper']}>
// 				<Select
// 					placeholder="Export As"
// 					onChange={handleChange}
// 					className="export-select"
// 					options={[
// 						{ value: 'pdf', label: 'pdf' },
// 						{ value: 'xls', label: 'xls' },
// 					]}
// 					style={{ width: 150 }}
// 				/>
// 				<span
// 					// onClick={handleExport}
// 					className={styles['export-data__wrapper-svg']}
// 				>
// 					{isLoading ? (
// 						<img src="/assets/gifs/loading-black.gif" height={40} />
// 					) : (
// 						<Tooltip title={`Export ${type}`}>
// 							<DownloadOutlined onClick={submitHandler} />
// 						</Tooltip>
// 					)}
// 				</span>
// 			</div>
// 		</div>
// 	);
// };

// export default Export;
import { Button, Dropdown, MenuProps, Space } from 'antd';
import React from 'react';
import styles from './index.module.scss';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';

interface Props {
	handleExport: (type: string) => void;
	isLoading: boolean;
}

const Export = (props: Props) => {
	const { isLoading, handleExport } = props;
	const items: MenuProps['items'] = [
		{
			label: 'pdf',
			key: 'pdf',
		},
		{
			label: 'xls',
			key: 'xls',
		},
	];

	const submitHandler = (e: any) => {
		const selectedType = e.key;
		if (selectedType) {
			handleExport(selectedType);
		}
	};

	const menuProps = {
		items,
		onClick: submitHandler,
	};

	return (
		<div className={`${styles['export-data']}`}>
			<div className={styles['export-data__wrapper']}>
				<Dropdown menu={menuProps}>
					<Button className="export-button">
						<Space>
							Export As
							{isLoading ? (
								<LoadingOutlined />
							) : (
								<DownloadOutlined />
							)}
						</Space>
					</Button>
				</Dropdown>
			</div>
		</div>
	);
};

export default Export;
