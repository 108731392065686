import { Flex, Switch, Table, Image, Tooltip } from 'antd';
import Column from 'antd/es/table/Column';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import { DisconnectOutlined } from '@ant-design/icons';
import { getApi, postApi, putApi } from 'apis';
import DisconnectModal from '../DisconnectModal';
import { toastText } from 'utils/utils';
import { getConnectionsAction } from '../../../../redux/actions/connectionsAction';
import { AppDispatch } from 'redux/store';
import APIKeyModal from 'components/Global/APIKeyModal';

const ConnectionTable = () => {
	const connectionData = useSelector(
		(state: any) => state?.connections?.data
	);

	const dispatch = useDispatch<AppDispatch>();

	// const [connections] = useState(connectionData);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isAPIKeyModalOpen, setIsAPIKeyModalOpen] = useState(false);
	const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [channelType, setChannelType] = useState(null);
	const [id, setId] = useState(null);

	const statusHandler = async (value: boolean, data: any) => {
		if (data.channelType === 'QBO') {
			try {
				const response = await postApi('/qbo/update-status', {
					status: value,
					connectionId: data.id,
				});

				toastText(response.data?.message, 'success');
			} catch (error: any) {
				console.log('error: ', error);
				toastText(error?.response?.data?.message, 'error');
			}
		}
		if (data.channelType === 'HUB_SPOT') {
			try {
				const response = await putApi('/hubspot/', {
					status: value,
				});

				toastText(response.data?.message, 'success');
			} catch (error: any) {
				toastText(error?.response?.data?.message, 'error');
				console.log('error: ', error);
			}
		}
		await dispatch(getConnectionsAction());
	};

	const disconnectHandler = async () => {
		if (channelType == 'QBO') {
			try {
				setIsLoading(true);
				const response = await postApi('/qbo/disconnect', {
					connectionId: id,
				});
				toastText(response.data?.message, 'success');
			} catch (error: any) {
				toastText(error?.response?.data?.message, 'error');
				console.log('error: ', error);
			} finally {
				setIsModalOpen(false);
				setChannelType(null);
				setIsLoading(false);
				dispatch(getConnectionsAction());
			}
		}
		if (channelType == 'HUB_SPOT') {
			try {
				setIsLoading(true);
				const response = await postApi('/hubspot/disconnect');
				toastText(response.data?.message, 'success');
			} catch (error: any) {
				toastText(error?.response?.data?.message, 'error');
				console.log('error: ', error);
			} finally {
				setIsModalOpen(false);
				setChannelType(null);
				setIsLoading(false);
				dispatch(getConnectionsAction());
			}
		}
	};
	const reConnectHandler = async () => {
		if (channelType == 'QBO') {
			try {
				setIsLoading(true);
				const response = await getApi('/qbo/auth-url');
				if (response.data?.responseStatus === 200) {
					window.open(response.data?.authUri, '_self');
				}
			} catch (error: any) {
				console.log('error: ', error);
			} finally {
				setIsConnectModalOpen(false);
				setChannelType(null);
				setIsLoading(false);
				dispatch(getConnectionsAction());
			}
		}

		if (channelType == 'HUB_SPOT') {
			setIsAPIKeyModalOpen(true);
		}
	};
	const setIsAPIKeyModalClose = () => {
		setIsAPIKeyModalOpen(false);
		setIsConnectModalOpen(false);
		setChannelType(null);
		setIsLoading(false);
		dispatch(getConnectionsAction());
	};

	const checkConnectionType = () => {
		if (channelType == 'QBO') {
			setIsConnectModalOpen(true);
		}
		if (channelType == 'HUB_SPOT') {
			setIsAPIKeyModalOpen(true);
		}
	};

	// useEffect(() => {
	// 	dispatch(getConnectionsAction());
	// }, []);
	return (
		<div>
			<Table
				dataSource={connectionData}
				pagination={false}
				className={styles['connections']}
				rowKey={(record) => record.id}
			>
				<Column
					title="Connected Apps"
					dataIndex="channelType"
					key="channelType"
					className="bg-white"
					width="30%"
					render={(_, record: any) => {
						return (
							<Image
								src={
									record.channelType === 'QBO'
										? '/assets/images/quickbooks-logo.png'
										: '/assets/images/hubspot.png'
								}
								style={{ height: '30px' }}
								preview={false}
							/>
						);
					}}
				/>
				<Column
					title="Company Name"
					dataIndex="tenantName"
					key="tenantName"
					className="bg-white"
					width="40%"
				/>

				<Column
					title="Action"
					dataIndex="action"
					key="action"
					className="bg-white"
					width="30%"
					render={(value: any, data: any) => {
						return (
							<Flex gap={30} align="center">
								<Tooltip
									title={`${
										data.status ? 'Stop' : 'Start'
									} data syncing from ${
										data.channelType === 'HUB_SPOT'
											? 'HUBSPOT'
											: 'QBO'
									}`}
								>
									<Switch
										key={data.id}
										checked={data?.status}
										defaultChecked={data?.status}
										onChange={(e) => statusHandler(e, data)}
									/>
								</Tooltip>
								{data.isConnected ? (
									<Tooltip
										title={`Disconnect ${
											data.channelType === 'HUB_SPOT'
												? 'HUBSPOT'
												: 'QBO'
										} company`}
									>
										<div
											className={
												styles[
													'connections__disconnect'
												]
											}
											onClick={() => {
												setChannelType(
													data.channelType
												);
												setId(data.id);
												setIsModalOpen(true);
											}}
										>
											<DisconnectOutlined
												style={{ fontSize: '2rem' }}
											/>
											<span>Disconnect</span>
										</div>
									</Tooltip>
								) : (
									<Tooltip
										title={`Connect ${
											data.channelType === 'HUB_SPOT'
												? 'HUBSPOT'
												: 'QBO'
										} company`}
									>
										<div
											className={
												styles[
													'connections__disconnect'
												]
											}
											onClick={() => {
												setChannelType(
													data.channelType
												);
												checkConnectionType();
											}}
										>
											<DisconnectOutlined
												style={{ fontSize: '2rem' }}
											/>
											<span>Connect</span>
										</div>
									</Tooltip>
								)}
							</Flex>
						);
					}}
				/>
			</Table>
			{isModalOpen && (
				<DisconnectModal
					isModalOpen={isModalOpen}
					handleOk={disconnectHandler}
					handleCancel={() => {
						setIsModalOpen(false);
						setChannelType(null);
					}}
					text={
						channelType === 'QBO'
							? 'Are you sure, you want to disconnect QuickBooks?'
							: 'Are you sure, you want to disconnect HubSpot'
					}
					buttonText="Yes"
					buttonColor="disconnect"
					image="/assets/images/disconnect-image.png"
					isLoading={isLoading}
				/>
			)}
			{isConnectModalOpen && (
				<DisconnectModal
					isModalOpen={isConnectModalOpen}
					handleOk={reConnectHandler}
					handleCancel={() => {
						setIsConnectModalOpen(false);
						setChannelType(null);
					}}
					text="Are you sure, you want to Reconnect?"
					buttonText="Yes"
					// buttonColor="connect"
					image={
						channelType === 'QBO'
							? '/assets/images/quickbooks-logo.png'
							: channelType === 'HUB_SPOT'
							? '/assets/images/hubspot.png'
							: '/assets/gifs/loading-black.gif'
					}
					// image="assets/images/connect-image.png"
					isLoading={isLoading}
					isReconnect={true}
				/>
			)}
			{isAPIKeyModalOpen ? (
				<APIKeyModal
					keyModalVisible={isAPIKeyModalOpen}
					setKeyModalVisible={setIsAPIKeyModalClose}
				/>
			) : null}
		</div>
	);
};

export default ConnectionTable;
