import { Col, Row } from 'antd';
import './index.scss';
import { AddSvg } from 'utils/svgs';
import { SideDrawerWrapper } from 'components/Global';
import { useState } from 'react';
import InviteSalesRep from '../InviteSalesReps';
import SalesRepTable from './Table';
import Buttons from 'components/Global/Buttons';

interface Props {
	changeCustomerTab: () => void;
	changeSalesRep: (id: string) => void;
	selectedSalesRep: string | null;
}

const SalesRepComponent = (props: Props) => {
	const { changeCustomerTab, changeSalesRep, selectedSalesRep } = props;

	const [drawerAnimation, setDrawerAnimation] = useState<boolean>(false);
	const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
	const [drawerInfo, setDrawerInfo] = useState({
		drawerTitle: 'Sales Reps',
	});
	const [buttons] = useState([
		{
			text: 'Invite Sales Rep',
			isLoading: false,
			className: 'primary-button',
			fontSize: '1.7rem',
			minWidth: '20rem',
			minHeight: '4rem',
			icon: <AddSvg />,
			styles: {},
			isSubmit: false,
			onclick: () => {
				openDrawerHandler();
			},
		},
	]);
	const openDrawerHandler = () => {
		setDrawerInfo({ drawerTitle: 'Invite Sales Rep' });
		setDrawerAnimation(true);
		setSideDrawerOpen(true);
	};
	// Remove the side drawer
	const removeDrawerFromDom = () => {
		setSideDrawerOpen(false);
	};
	// Close the side drawer with animation
	const closeDrawerByAnimation = () => {
		setDrawerAnimation(false);
	};

	return (
		<div>
			<Row justify="center">
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className={'invite-sales-rep-header'}>
						<Buttons buttons={buttons} gap="large" />
					</div>
				</Col>
			</Row>
			<div>
				<SalesRepTable
					changeCustomerTab={changeCustomerTab}
					changeSalesRep={changeSalesRep}
					selectedSalesRep={selectedSalesRep}
				/>
			</div>

			{isSideDrawerOpen && (
				<SideDrawerWrapper
					isOpen={drawerAnimation}
					removeDrawerFromDom={removeDrawerFromDom}
					closeDrawerByAnimation={closeDrawerByAnimation}
					headerTitle={drawerInfo.drawerTitle}
					position="right"
					width="half"
				>
					<InviteSalesRep
						closeDrawerByAnimation={closeDrawerByAnimation}
					/>
				</SideDrawerWrapper>
			)}
		</div>
	);
};

export default SalesRepComponent;
