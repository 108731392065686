import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import { Tooltip } from 'antd';

interface EllipseInterface {
	message: string;
	maxLength: number | string;
	isTooltip?: boolean;
	tooltipMessage?: string;
}

const Ellipse = (props: EllipseInterface) => {
	const { message, isTooltip, tooltipMessage, maxLength = 10 } = props;
	const [truncatedText, setTruncatedText] = useState(message);
	const textRef = useRef<any>(null);

	useEffect(() => {
		const handleResize = () => {
			if (textRef.current) {
				const fullText = textRef.current.textContent;
				if (fullText.length > maxLength) {
					const truncated = fullText.substring(0, maxLength) + '...';
					setTruncatedText(truncated);
				}
			}
		};

		handleResize(); // Initial check 
	}, [message]);

	return (
		<div className={styles['ellipse-container']} ref={textRef}>
			{isTooltip ? (
				<Tooltip title={tooltipMessage}>{truncatedText}</Tooltip>
			) : (
				truncatedText
			)}
		</div>
	);
};

export default Ellipse;
