import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import NewProductReportTable from './Table';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { getAllSalesRepsAction } from '../../redux/actions/salesRepsAction';
import { getApi } from 'apis';
import { toastText } from 'utils/utils';

export const NewProductReport = () => {
	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		dispatch(getAllSalesRepsAction());
	}, []);

	const allSalesRep = useSelector((state: any) => state.salesReps.data);

	const [newProducts, setNewProducts] = useState([
		{
			value: '',
			label: 'All Products',
		},
	]);

	const getNewProducts = async () => {
		try {
			const response = await getApi('/products/new');
			if (response.data.data && response.data.data.length > 0) {
				const data = response.data.data.map((product: any) => {
					return {
						value: product.id,
						label: product.name,
					};
				});
				setNewProducts((prev) => {
					return [...prev, ...data];
				});
			}
		} catch (error: any) {
			toastText('Something went wrong', 'error');
		}
	};
	useEffect(() => {
		getNewProducts();
	}, []);
	return (
		<div>
			<Row justify="center">
				<Col xs={24} sm={24} md={24} lg={24}>
					<NewProductReportTable
						allSalesRep={allSalesRep}
						newProducts={newProducts}
					/>
				</Col>
			</Row>
		</div>
	);
};
