import { createSlice } from '@reduxjs/toolkit';
import { logoutAction } from '../actions/logoutAction';

const initialState: any = {
	isLoading: false,
	error: null,
};

const AuthSlice = createSlice({
	name: 'Auth',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(logoutAction.pending, (state) => {
			state.isLoading = true;
			state.error = null;
		});
		builder.addCase(logoutAction.fulfilled, (state) => {
			state.isLoading = false;
			localStorage.clear();
		});
		builder.addCase(logoutAction.rejected, (state, action: any) => {
			state.isLoading = false;
			state.error = action.payload;
		});
	},
});

export default AuthSlice;
