import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApi } from 'apis';

export const getGlobalConstantsAction = createAsyncThunk(
	'config',
	async (_, { rejectWithValue }) => {
		try {
			const response = await getApi('/config/');
			return response.data;
		} catch (error: any) {
			if (!error.response) {
				throw error;
			}
			return rejectWithValue(error?.response?.data);
		}
	}
);
