import { Modal, Row, Select } from 'antd';
import Buttons from 'components/Global/Buttons';
import styles from './index.module.scss';
import { useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { postApi } from 'apis';
import { toastText } from 'utils/utils';

interface Props {
	isOpen: boolean;
	handleOk: () => void;
	handleCancel: () => void;
	products: any;
	getSpecialProducts: () => void;
}

const SpecialProductModal = (props: Props) => {
	const { isOpen, handleCancel, handleOk, products, getSpecialProducts } =
		props;
	const [isLoading, setIsLoading] = useState(false);

	const myButtons = [
		{
			text: 'Add',
			isLoading: isLoading,
			className: 'primary-button',
			fontSize: '1.7rem',
			minWidth: '12rem',
			minHeight: '4rem',
			styles: {},
			isSubmit: true,
			onclick: () => {
				handleAddProduct();
			},
		},
		{
			text: 'Reset',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.7rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			styles: {},
			onclick: () => {
				handleReset();
			},
		},
	];

	const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

	const [filteredProducts, setFilteredProducts] = useState(products);

	const handleReset = () => {
		setFilteredProducts(products);
		setSelectedProducts([]);
	};

	const handleAddProduct = async () => {
		try {
			setIsLoading(true);

			const response = await postApi('/products/special', {
				productIds: selectedProducts,
			});
			getSpecialProducts();
			handleCancel();
			toastText(response.data?.message, 'success');
		} catch (error: any) {
			toastText(error.response.data.message, 'error');
		} finally {
			setIsLoading(false);
			handleCancel();
		}
	};

	const handleChange = (value: string | string[]) => {
		setSelectedProducts(Array.isArray(value) ? value : [value]);
	};

	return (
		<div>
			<Modal
				open={isOpen}
				onOk={handleOk}
				closable={false}
				// confirmLoading={confirmLoading}
				onCancel={handleCancel}
				footer={() => (
					<div className={styles['modal-footer']}>
						<Buttons buttons={myButtons} gap="large" />
					</div>
				)}
			>
				<Row className={styles['modal-header']}>
					<p className={styles['modal-header_title']}>
						Select Products/SKU
					</p>
					<div className="pointer">
						<CloseOutlined
							className={styles['modal-header_close']}
							onClick={handleCancel}
							style={{
								fontSize: '2rem',
								fontWeight: '500',
							}}
						/>
					</div>
				</Row>
				<div className={styles['modal-body']}>
					<Select
						mode="multiple"
						size="middle"
						placeholder="Please select special products"
						onChange={handleChange}
						style={{ width: '100%' }}
						options={filteredProducts.map((product: any) => ({
							label: product.name,
							value: product.id,
						}))}
						value={selectedProducts}
						optionFilterProp="label"
					/>
				</div>
			</Modal>
		</div>
	);
};

export default SpecialProductModal;
