import Buttons from 'components/Global/Buttons';
import { useEffect, useState } from 'react';
import { AddSvg } from 'utils/svgs';
import SpecialProductModal from './Modal';
import styles from './index.module.scss';
import { getApi } from 'apis';
import { toastText } from 'utils/utils';
import SpecialProductTable from './Table';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const SpecialProduct = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [products, setProducts] = useState([]);
	const [specialProducts, setSpecialProducts] = useState([]);
	const [searchValue, setSearchValue] = useState('');

	const [buttons] = useState([
		{
			text: 'Add Special Product',
			isLoading: false,
			className: 'primary-button',
			fontSize: '1.7rem',
			minWidth: '20rem',
			minHeight: '4rem',
			icon: <AddSvg />,
			styles: {},
			isSubmit: false,
			onclick: () => {
				getProducts();
			},
		},
	]);

	const handleSubmit = () => {
		console.log('Submit');
	};

	const getProducts = async () => {
		try {
			const response = await getApi('/products');
			setProducts(response.data.data);
			setIsModalOpen(true);
		} catch (error: any) {
			toastText('Something went wrong', 'error');
		}
	};

	const getSpecialProducts = async () => {
		try {
			const response = await getApi('/products/special', {
				search: searchValue,
			});
			setSpecialProducts(response.data.data);
		} catch (error: any) {
			toastText('Something went wrong', 'error');
		}
	};
	const searchHandler = (value: any) => {
		setSearchValue(value);
	};

	useEffect(() => {
		getSpecialProducts();
	}, [searchValue]);

	return (
		<div className={styles['new-product-layout']}>
			<div className={styles['new-product-header']}>
				<Input
					className={styles['search']}
					placeholder="Search special products..."
					suffix={<SearchOutlined />}
					onChange={(e) => {
						searchHandler(e.target.value);
					}}
					value={searchValue}
					size="large"
				/>

				<Buttons buttons={buttons} gap="large" />
			</div>
			<div>
				<SpecialProductTable
					specialProducts={specialProducts}
					getSpecialProducts={getSpecialProducts}
				/>
			</div>

			{isModalOpen && (
				<SpecialProductModal
					handleOk={handleSubmit}
					isOpen={isModalOpen}
					handleCancel={() => setIsModalOpen(false)}
					products={products}
					getSpecialProducts={getSpecialProducts}
				/>
			)}
		</div>
	);
};

export default SpecialProduct;
