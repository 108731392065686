import { Select, Table } from 'antd';
import Column from 'antd/es/table/Column';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface Props {
	changeSalesRep: (id: string) => void;
	selectedSalesRep: string | null;
	totalRecords: any;
	currentPage: any;
	pageSize: any;
	paginationChangeHandler: any;
	pageSizeHandler: any;
	isLoading: boolean;
}

const ViewBySales = (props: Props) => {
	const { changeSalesRep, selectedSalesRep, isLoading } = props;

	const customers = useSelector((state: any) => state.salesReps.details);
	const { data: allSalesRep } = useSelector((state: any) => state.salesReps);

	const [salesRepOptions, setSalesRepOptions] = useState([]);

	const handleSalesRep = (e: any) => {
		changeSalesRep(e);
	};

	useEffect(() => {
		if (allSalesRep?.length > 0) {
			const data = allSalesRep.map((salesRep: any) => {
				return {
					value: salesRep.id,
					label:
						salesRep?.firstName || salesRep?.lastName
							? `${salesRep?.firstName}  ${salesRep?.lastName}`
							: salesRep?.email,
				};
			});
			setSalesRepOptions(data);
		}
	}, [allSalesRep]);

	return (
		<div>
			<span style={{ fontSize: '1.5rem' }}>Sales Rep : </span>

			<Select
				showSearch
				optionFilterProp="label"
				style={{ width: 300, marginBottom: '2rem' }}
				onChange={handleSalesRep}
				options={salesRepOptions}
				value={selectedSalesRep}
				placeholder="Select Sales Rep"
			/>
			<Table
				dataSource={customers}
				pagination={false}
				// pagination={{
				// 	total: totalRecords,
				// 	current: currentPage,
				// 	onChange: paginationChangeHandler,
				// 	pageSize: pageSize,
				// 	showSizeChanger: true,
				// 	onShowSizeChange: pageSizeHandler,
				// 	pageSizeOptions: [10, 20, 50, 100, 200],
				// }}
				scroll={{ y: 'calc(100vh - 320px)' }}
				loading={isLoading}
			>
				<Column
					title="Customer Name"
					dataIndex="customerName"
					key="customerName"
					className="bg-white"
					render={(value, data: any) => {
						return (
							<div>
								{data.firstName || data.lastName
									? `${data.firstName} ${data.lastName}`
									: data.email}
							</div>
						);
					}}
				/>
				<Column
					title="Inside Sales Rep"
					dataIndex="salesrepName"
					key="salesrepId"
					className="bg-white"
				/>
				<Column
					title="Outside Sales Rep"
					dataIndex="salesrep2Name"
					key="salesrep2Id"
					className="bg-white"
				/>
				<Column
					title="Retention Rep"
					dataIndex="retentionSalesRepName"
					key="retentionSalesRep"
					className="bg-white"
				/>
			</Table>
		</div>
	);
};

export default ViewBySales;
