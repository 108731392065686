import { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { invalidText } from 'utils/utils';
import { jwtDecode } from 'jwt-decode';
import { AppDispatch } from '../../../redux/store';
import { fetchProfileAction } from '../../../redux/actions/profileAction';

export const RoleContext = createContext<any>({
	role: null,
});

export const AuthLayout = () => {
	const authpath = [
		'/login',
		'/reset-password',
		'/forgot-password',
		'/set-password',
		'/register',
	];

	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const [role, setRole] = useState('');

	const { pathname } = useLocation();
	const token = localStorage.getItem('accessToken');
	const path = window.location.pathname;

	const fetchProfile = async () => {
		const response = await dispatch(fetchProfileAction());
		if (response.payload?.responseStatus === 401) {
			navigate('/login');
		}
	};

	useEffect(() => {
		if (!authpath.includes(path) && invalidText(token)) {
			navigate('/login');
		}
	}, [pathname]);

	useEffect(() => {
		if (token) {
			fetchProfile();
			if (path === '/login') {
				navigate('/');
			}
		}
	}, []);

	useEffect(() => {
		if (token) {
			const userData: User = jwtDecode(token as string);
			if (userData.type) {
				setRole(userData.type);
			}
		}
	}, [token]);

	const roleHandler = (value: string) => {
		setRole(value);
	};

	return (
		<RoleContext.Provider
			value={{
				role,
				roleHandler,
			}}
		>
			<Outlet />
		</RoleContext.Provider>
	);
};

interface User {
	email: string;
	exp: number;
	iat: number;
	id: string;
	type: string;
}
