import { Layout, Menu } from 'antd';
import { FC } from 'react';
import styles from './index.module.scss';
import './index.scss';
import { SidebarProps } from './types';

// Global sidebar
const Sidebar: FC<SidebarProps> = (props) => {
	const { Sider } = Layout;
	const { handleSidebar, items, selectedSidebar } = props;

	return (
		<Sider
			style={{
				background: '#fff',
				maxHeight: '100%',
				height: '100%',
				overflow: 'auto',
			}}
			className={styles.sidebar}
		>
			<div className={styles.sidebar__wrapper}>
				<Menu
					mode="inline"
					selectedKeys={[selectedSidebar]}
					defaultSelectedKeys={[selectedSidebar]}
					items={items}
					onClick={handleSidebar}
					className="menu-item "
					style={{
						background: '#fff',
						padding: 0,
					}}
				/>
			</div>
		</Sider>
	);
};

export default Sidebar;
