import { Tabs } from 'antd';
import { RoleContext } from 'components/Global/AuthLayout';
import Customers from 'components/SalesReps/Customers';
import SalesRepComponent from 'components/SalesReps/SalesReps';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import {
	getAllSalesRepsAction,
	getSalesRepDetailsAction,
} from '../../redux/actions/salesRepsAction';
import './index.scss';

export const SalesReps = () => {
	const roleContext = useContext(RoleContext);
	const dispatch = useDispatch<AppDispatch>();

	const { customerCount } = useSelector((state: any) => state.salesReps);

	const [isSalesReps, setIsSalesReps] = useState(
		roleContext.role === 'ADMIN' ? 'sales-rep' : 'customer'
	);

	const [selectedSalesRep, setSelectedSalesRep] = useState<null | string>(
		null
	);

	const [isViewBySalesRep, setIsViewBySalesRep] = useState(true);

	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [isLoading, setIsLoading] = useState(false);

	const fetchSalesRep = () => {
		dispatch(getAllSalesRepsAction());
	};

	useEffect(() => {
		// if (roleContext.role === 'ADMIN') {
		fetchSalesRep();
		// }
		setIsSalesReps(roleContext.role === 'ADMIN' ? 'sales-rep' : 'customer');
	}, [roleContext.role]);

	const changeCustomerTab = () => {
		setIsSalesReps('customer');
	};

	const changeSalesRep = (id: string) => {
		setSelectedSalesRep(id);
		setCurrentPage(1);
	};

	const changeTab = (key: any) => {
		setIsSalesReps(key);
		changeSwitch(key);
		setCurrentPage(1);
	};

	const changeSwitch = (e: any) => {
		setIsViewBySalesRep(e);
		setCurrentPage(1);
	};

	const fetchAllSalesRep = async () => {
		try {
			setIsLoading(true);
			await dispatch(
				getSalesRepDetailsAction({
					salesRepId: selectedSalesRep,
					page: currentPage,
					limit: pageSize,
				})
			);
		} catch (err) {
			console.log('error: ', err);
		} finally {
			setIsLoading(false);
		}
	};
	const paginationChangeHandler = (value: number) => {
		setCurrentPage(value);
	};

	const pageSizeHandler = (currentPage: number, pageSize: any) => {
		// setCurrentPage(currentPage);
		setPageSize(pageSize);
	};
	let items = [];
	if (roleContext.role === 'ADMIN') {
		items = [
			{
				key: 'sales-rep',
				label: 'Sales Reps',
				children: (
					<SalesRepComponent
						changeCustomerTab={changeCustomerTab}
						changeSalesRep={changeSalesRep}
						selectedSalesRep={selectedSalesRep}
					/>
				),
			},
			{
				key: 'customer',
				label: 'Customers',
				children: (
					<Customers
						changeSalesRep={changeSalesRep}
						selectedSalesRep={selectedSalesRep}
						changeSwitch={changeSwitch}
						isViewBySalesRep={isViewBySalesRep}
						totalRecords={customerCount}
						currentPage={currentPage}
						pageSize={pageSize}
						paginationChangeHandler={paginationChangeHandler}
						pageSizeHandler={pageSizeHandler}
						isLoading={isLoading}
					/>
				),
			},
		];
	} else {
		items = [
			{
				key: 'customer',
				label: 'Customers',
				children: (
					<Customers
						changeSalesRep={changeSalesRep}
						selectedSalesRep={selectedSalesRep}
						changeSwitch={changeSwitch}
						isViewBySalesRep={isViewBySalesRep}
						totalRecords={customerCount}
						currentPage={currentPage}
						pageSize={pageSize}
						paginationChangeHandler={paginationChangeHandler}
						pageSizeHandler={pageSizeHandler}
						isLoading={isLoading}
					/>
				),
			},
		];
	}

	useEffect(() => {
		if (selectedSalesRep) {
			fetchAllSalesRep();
		}
	}, [selectedSalesRep, currentPage, pageSize]);

	return (
		<div>
			<Tabs
				defaultActiveKey={isSalesReps}
				activeKey={isSalesReps}
				items={items}
				onChange={(key) => changeTab(key)}
				style={{ fontSize: '18px' }}
			/>
		</div>
	);
};
