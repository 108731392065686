import { Button, Image, Modal, Spin } from 'antd';
import styles from './index.module.scss';
import './index.scss';
import { DisconnectModalInterface } from './types';

const DisconnectModal = (props: DisconnectModalInterface) => {
	const {
		isModalOpen,
		handleOk,
		handleCancel,
		buttonText,
		text,
		buttonColor,
		image,
		isLoading,
		isReconnect,
	} = props;

	return (
		<div>
			<Modal
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<div
						className={styles['modal-body__buttons']}
						key={Math.random()}
					>
						{isReconnect ? (
							<div
								onClick={handleOk}
								className={styles.integration__card__button}
							>
								<Button className="primary-button">
									Connect
								</Button>
								{/* <ConnectToQuickbooks /> */}
							</div>
						) : (
							<>
								<Button
									key="submit"
									className={`${
										styles[
											`modal-body__buttons--${buttonColor}`
										]
									}  
							} ${isLoading && 'pointer-event-none'}`}
									onClick={handleOk}
								>
									{isLoading ? (
										<div
											style={{
												width: '100%',
												minHeight: '200px',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<Spin />
										</div>
									) : (
										// <img
										// 	src="/assets/gifs/loading-black.gif"
										// 	height={40}
										// />
										buttonText
									)}
								</Button>
								<Button
									key="cancel"
									className={
										styles['modal-body__buttons--cancel']
									}
									onClick={handleCancel}
								>
									No
								</Button>
							</>
						)}
					</div>,
				]}
			>
				<div className={styles['modal-body']}>
					<Image
						className={
							isReconnect
								? styles['modal-body__image_reconnect']
								: styles['modal-body__image']
						}
						src={image}
						preview={false}
					/>
					{!isReconnect && (
						<p className={styles['modal-body__text']}>{text}</p>
					)}
				</div>
			</Modal>
		</div>
	);
};

export default DisconnectModal;
