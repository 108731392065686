import { postApi } from 'apis';
// import axios from 'axios';
import { LoginLayoutBody } from 'components/Login';
import { FORMDATA } from 'constants/Data';
import { LoginLayout } from 'layouts/Login';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from 'redux/store';
import { toastText } from 'utils/utils';
import { fetchProfileAction } from '../../redux/actions/profileAction';

// Login page
const Login = () => {
	// React Hooks

	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const [isLoading, setIsLoading] = useState(false);

	const [isPasswordValidate, setIsPasswordValidate] = useState(false);

	// const roleContext = useContext(RoleContext);

	const onSubmit = async (values: any) => {
		if (isPasswordValidate) {
			setIsLoading(true);
			try {
				const res = await postApi(`/auth/login`, values);
				localStorage.setItem('accessToken', res.data.accessToken);
				await dispatch(fetchProfileAction());
				setIsLoading(false);

				navigate('/settings/integrations');
			} catch (error: any) {
				toastText(error.response.data?.message, 'error');
				setIsLoading(false);
			}
		}
	};

	// JSX for the Login page
	return (
		<LoginLayout>
			<LoginLayoutBody
				title="Log in"
				description="<p>
							Welcome to <strong> Energy Efficient Solution Comission! </strong>Please Enter your
							Details.
						</p>"
				formData={FORMDATA.loginFields}
				buttonTitle={'Sign in'}
				redirectUrl="/forgot-password"
				redirectText="Forgot password?"
				onSubmit={onSubmit}
				isLoading={isLoading}
				setIsPasswordValidate={setIsPasswordValidate}
			></LoginLayoutBody>
		</LoginLayout>
	);
};

export default Login;
