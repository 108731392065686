import { Button, Flex } from 'antd';
import { Card, Image } from 'antd';
import styles from './index.module.scss';

interface CardInterface {
	id: string;
	logo: string;
	ghost: boolean;
	buttonText: string;
	color: string;
	backgroundColor: string;
	isLoading?: boolean;
	disabled: boolean;
	onClick?: () => void;
}

interface Prop {
	cards: CardInterface[];
}

const Cards = (props: Prop) => {
	const { cards } = props;
	return (
		<div>
			<Flex gap={50} align="center" wrap="wrap">
				{cards.map((card: CardInterface, index: number) => (
					<Card
						key={index}
						className={styles['integration__card']}
						id={card?.id}
					>
						<div className={styles['integration__card--body']}>
							<Image preview={false} src={card?.logo} />

							<Button
								ghost={card?.ghost}
								onClick={card?.onClick}
								style={{
									backgroundColor: card.backgroundColor,
									color: card.color,
								}}
								className={styles['integration__card--button']}
								loading={card?.isLoading}
								disabled={card?.disabled}
							>
								{card?.buttonText}
							</Button>
						</div>
					</Card>
				))}
			</Flex>
		</div>
	);
};

export default Cards;
