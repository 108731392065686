import { Button, Col, Form, Input, Popconfirm, Row } from 'antd';
import { putApi } from 'apis';
import { FORMDATA } from 'constants/Data';
import BackgroundComponent from 'layouts/Background';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { toastText } from 'utils/utils';
import { getGlobalConstantsAction } from '../../../redux/actions/globalConstant';
import styles from './index.module.scss';

const CommissionConfiguration = () => {
	const { globalConstantItems } = FORMDATA;

	const dispatch = useDispatch<AppDispatch>();

	const globalConstantData = useSelector(
		(state: any) => state?.globalConstants?.data
	);

	const [isLoading, setIsLoading] = useState(false);

	const [globalConstantValue, setGlobalConstantValue] = useState<any>({
		globalOverHeadRatio: globalConstantData?.globalOverHeadRatio ?? 0,
		newProductIntroduction: globalConstantData?.newProductIntroduction ?? 0,
		wireTransferFee: globalConstantData?.wireTransferFee ?? 0,
		cashFee: globalConstantData?.cashFee ?? 0,
		checkFee: globalConstantData?.checkFee ?? 0,
		googlePayment: globalConstantData?.googlePayment ?? 0,
		amazonFee: globalConstantData?.amazonFee ?? 0,
		paypalFee: globalConstantData?.paypalFee ?? 0,
		ebayFee: globalConstantData?.ebayFee ?? 0,
		creditCardFee: globalConstantData?.creditCardFee ?? 0,
	});

	const resetHandler = () => {
		setGlobalConstantValue({
			globalOverHeadRatio: globalConstantData?.globalOverHeadRatio ?? 0,
			newProductIntroduction:
				globalConstantData?.newProductIntroduction ?? 0,
			wireTransferFee: globalConstantData?.wireTransferFee ?? 0,
			cashFee: globalConstantData?.cashFee ?? 0,
			checkFee: globalConstantData?.checkFee ?? 0,
			googlePayment: globalConstantData?.googlePayment ?? 0,
			amazonFee: globalConstantData?.amazonFee ?? 0,
			paypalFee: globalConstantData?.paypalFee ?? 0,
			ebayFee: globalConstantData?.ebayFee ?? 0,
			creditCardFee: globalConstantData?.creditCardFee ?? 0,
		});
	};
	const onFinish = async (values: any) => {
		setGlobalConstantValue(values);
		try {
			setIsLoading(true);
			const response = await putApi('/config/', globalConstantValue);
			toastText(response.data.message, 'success');
			// setOpen(false);
			await dispatch(getGlobalConstantsAction());
		} catch (error: any) {
			toastText(error.response.data?.message, 'error');
		} finally {
			setIsLoading(false);
			// setConfirmLoading(false);
		}
	};

	const handleInputChange = (fieldName: string, value: string) => {
		const parsedValue = parseFloat(value);

		// If wireTransferFee, validate input as a positive number
		if (fieldName === 'wireTransferFee') {
			if (!isNaN(parsedValue) && parsedValue >= 0 && value.length <= 10) {
				setGlobalConstantValue((prevValues: any) => ({
					...prevValues,
					[fieldName]: parsedValue,
				}));
			}
		} else {
			if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 100) {
				setGlobalConstantValue((prevValues: any) => ({
					...prevValues,
					[fieldName]: parsedValue,
				}));
			} else {
				if (parsedValue <= 0 || isNaN(parsedValue)) {
					setGlobalConstantValue((prevValues: any) => ({
						...prevValues,
						[fieldName]: 0,
					}));
				} else {
					setGlobalConstantValue((prevValues: any) => ({
						...prevValues,
						[fieldName]: 100,
					}));
				}
			}
		}
		if (value === '') {
			setGlobalConstantValue((prevValues: any) => ({
				...prevValues,
				[fieldName]: 0,
			}));
		}
	};
	// const showPopconfirm = () => {
	// 	console.log('show');
	// 	setOpen(true);
	// };

	const handleOk = async () => {
		setIsLoading(true);
		await onFinish(globalConstantValue);
	};

	const handleCancel = () => {
		console.log('first cancel');
		// setOpen(false);
	};

	useEffect(() => {
		if (globalConstantData) {
			setGlobalConstantValue(globalConstantData);
		}
	}, [globalConstantData]);
	useEffect(() => {
		dispatch(getGlobalConstantsAction());
	}, []);

	return (
		<div>
			<BackgroundComponent>
				<Form
					name="basic"
					className={styles['global-constant']}
					initialValues={globalConstantData}
				>
					{globalConstantItems.map((globalConstant, index) => (
						<Form.Item
							key={index}
							name={globalConstant.name}
							id={globalConstant.id}
						>
							<Row>
								<Col
									sm={12}
									xs={12}
									md={12}
									lg={12}
									className={styles['global-constant__title']}
								>
									<label>
										{index + 1 + ')'} {globalConstant.title}
									</label>
								</Col>
								<Col sm={12} xs={12} md={12} lg={12}>
									<div>
										<Input
											placeholder={
												globalConstant.placeHolder
											}
											value={
												globalConstantValue[
													globalConstant.name
												]
											}
											name={globalConstant.name}
											size="large"
											className={
												styles[
													'global-constant__title--input'
												]
											}
											suffix={
												globalConstant.id ===
												'wireTransferFee'
													? ''
													: '%'
											}
											prefix={
												globalConstant.id ===
												'wireTransferFee'
													? '$'
													: ''
											}
											type={globalConstant.type}
											onChange={(e) =>
												handleInputChange(
													globalConstant.name,
													e.target.value
												)
											}
										/>

										{globalConstant.id ===
											'wireTransferFee' && (
											<span> per transaction</span>
										)}
									</div>
								</Col>
							</Row>
						</Form.Item>
					))}
				</Form>
			</BackgroundComponent>
			<div
				style={{
					display: 'flex',
					justifyContent: 'start',
					gap: '10px',
				}}
			>
				<Popconfirm
					placement="topLeft"
					title="Changes to global constants may impact commission calculations."
					description={false}
					okText="Update"
					cancelText="Cancel"
					onConfirm={handleOk}
					onCancel={handleCancel}
					okButtonProps={{ loading: isLoading }}
				>
					<Button
						className="primary-button"
						size="large"
						style={{
							width: '10rem',
						}}
					>
						Save
					</Button>
				</Popconfirm>
				<Button
					className="secondary-button"
					onClick={() => resetHandler()}
					size="large"
					style={{
						width: '10rem',
					}}
				>
					Reset
				</Button>
			</div>
		</div>
	);
};

export default CommissionConfiguration;
