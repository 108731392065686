import { EditOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import { postApi } from 'apis';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { formatPhoneNumber, getPeriodCategory, toastText } from 'utils/utils';
import EditPlanModal from '../Modal';
import styles from './index.module.scss';
// import { useState } from 'react';

const { Column } = Table;

interface Props {
	changeCustomerTab: () => void;
	changeSalesRep: (id: string) => void;
	selectedSalesRep: string | null;
}

const SalesRepTable = (props: Props) => {
	const { changeCustomerTab, changeSalesRep } = props;

	const salesRep = useSelector((state: any) => state.salesReps.data);
	const isDataLoading = useSelector(
		(state: any) => state.salesReps.isLoading
	);

	// const [salesReps] = useState(salesRepData);

	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [salesRepId, setSalesRepId] = useState<null | string>(null);

	const [isLoading, setIsLoading] = useState(false);
	const [periodCategory, setPeriodCategory] = useState<null | string>(null);

	const viewMoreHandler = (data: any) => {
		changeSalesRep(data.id);
		changeCustomerTab();
	};

	const editPlanModalOpenHandler = async (data: any) => {
		setSalesRepId(data.id);
		const hubspotDate: any = new Date(data.hubSpotCreatedAt);
		const category = getPeriodCategory(hubspotDate);
		setPeriodCategory(category);
		setIsEditModalOpen(true);
	};

	const cancelHandler = () => {
		setIsEditModalOpen(false);
		setPeriodCategory(null);
	};

	const changeCategory = (value: string | null) => {
		setPeriodCategory(value);
	};

	const reinviteHandler = async (email: string) => {
		try {
			setIsLoading(true);
			const response = await postApi('/sales-rep/reinvite-salesrep', {
				email: email,
			});
			if (response.data.message) {
				toastText(response.data.message, 'success');
			}
		} catch (error: any) {
			toastText(error.response.data?.message, 'error');
			console.log('error: ', error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div>
			<Table
				dataSource={salesRep}
				pagination={false}
				scroll={{ y: 'calc(100vh - 310px)' }}
				rowKey={(record: any) => record.id}
				loading={isDataLoading}
			>
				<Column
					title="No."
					dataIndex="sr"
					key="sr"
					className="bg-white"
					render={(value, data, index) => {
						return `${index + 1}`;
					}}
					width="6%"
				/>
				<Column
					title="Name"
					dataIndex="name"
					key="name"
					className="bg-white"
					render={(value, data: any) => {
						return `${data.firstName}  ${data.lastName}`;
					}}
					width="12%"
				/>
				<Column
					title="Date Of Joining"
					dataIndex="doj"
					key="doj"
					className="bg-white"
					render={(value, data: any) => {
						return (
							data?.hubSpotCreatedAt &&
							moment(data?.hubSpotCreatedAt).format('MM/DD/YYYY')
						);
					}}
					width="11%"
				/>
				<Column
					title="Email Address"
					dataIndex="email"
					key="email"
					className="bg-white"
					width="15%"
				/>
				<Column
					title="Phone Number"
					dataIndex="phone"
					key="phone"
					className="bg-white"
					width="12%"
					render={(value) => {
						return value ? formatPhoneNumber(value) : '-';
					}}
				/>
				<Column
					title="Assigned Customers"
					dataIndex="customers"
					key="customers"
					className="bg-white"
					render={(value, data: any) => {
						return data?.isInvited ? (
							'-'
						) : data.customer && data.customer.length > 0 ? (
							<div>
								<ol>
									{data.customer
										.slice(0, 20)
										.filter(
											(customer: any) => customer !== ' '
										)
										.slice(0, 5)
										.map((customer: any, index: any) => (
											<li key={index}>{customer}</li>
										))}
								</ol>

								<p
									className={styles['view-more']}
									onClick={() => viewMoreHandler(data)}
								>
									View More
								</p>
							</div>
						) : (
							'-'
						);
					}}
					width="17%"
				/>
				<Column
					title="Commission Plan"
					dataIndex="commission"
					key="commission"
					className="bg-white"
					width="18%"
					render={(value, data: any) => {
						return data?.isInvited ? (
							'New Product Commission'
						) : value ? (
							value.length > 0 ? (
								value.map((plan: string, index: number) => (
									<p key={index}>{plan}</p>
								))
							) : (
								<Button
									className="primary-button"
									size="small"
									onClick={() =>
										editPlanModalOpenHandler(data)
									}
								>
									Add Commission
								</Button>
							)
						) : (
							<Button
								className="primary-button"
								size="small"
								onClick={() => editPlanModalOpenHandler(data)}
							>
								Add Commission
							</Button>
						);
					}}
				/>
				<Column
					title="Actions"
					dataIndex="actions"
					key="actions"
					className="bg-white"
					render={(value, data: any) => {
						return (
							<div className={styles['actions']}>
								{/* {!data?.isInvited && ( */}
								<Tooltip title="Edit Commission Plan">
									<div
										className={
											data?.isInvited
												? 'pointer-event-none disabled-text'
												: 'pointer'
										}
										onClick={() =>
											editPlanModalOpenHandler(data)
										}
									>
										<EditOutlined
											style={{
												fontSize: '18px',
											}}
										/>
									</div>
								</Tooltip>
								{/* )} */}
								{/* {!data?.isVerified && ( */}
								<Tooltip title="Resend invitation to sales representative to set password and login">
									<div
										className={
											isLoading || data?.isVerified
												? 'pointer-event-none disabled-text'
												: 'pointer'
										}
										onClick={() =>
											reinviteHandler(data.email)
										}
									>
										<MailOutlined
											style={{
												fontSize: '18px',
											}}
										/>
									</div>
								</Tooltip>
								{/* )} */}
							</div>
						);
					}}
					width="9%"
				/>
			</Table>
			{isEditModalOpen && (
				<EditPlanModal
					isOpen={isEditModalOpen}
					handleCancel={cancelHandler}
					salesRepId={salesRepId}
					periodCategory={periodCategory}
					changeCategory={changeCategory}
				/>
			)}
		</div>
	);
};

export default SalesRepTable;
