import { createAsyncThunk } from '@reduxjs/toolkit';
import { postApi } from 'apis';

export const logoutAction = createAsyncThunk(
	'auth/logout',
	async (data: any, { rejectWithValue }) => {
		try {
			// Make an API call to log the user out
			const response = await postApi('/auth/logout', data);
			if (response?.data?.statusCode === 200) {
				// Clear the accessToken cookie when the user logs out
				localStorage.removeItem('accessToken');
			}
			return response.data;
		} catch (error: any) {
			if (!error.response) {
				throw error;
			}
			return rejectWithValue(error?.response?.data);
		}
	}
);
