import {
	EmailSvg,
	IntegrationsSvg,
	PasswordSvg,
	SubscriptionSvg,
} from 'utils/svgs';

const phoneNumberValidator = (_: any, value: any) => {
	// Modify this regex pattern to match the format of phone numbers you want to validate
	const phoneRegex = /^[0-9]{10}$/;

	if (!value || value.match(phoneRegex)) {
		return Promise.resolve();
	}

	return Promise.reject(new Error('Please enter a valid phone number'));
};

const validateNoOnlySpaces = (_: any, value: any) => {
	if (value && value.trim() === '') {
		return Promise.reject('Please enter valid value');
	}
	return Promise.resolve();
};

export const FORMDATA = {
	loginFields: [
		{
			title: 'Email Address',
			id: 'email',
			type: 'text',
			name: 'email',
			svg: <EmailSvg />,
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please enter your email address',
					validateTrigger: 'onChange',
				},
				{
					type: 'email',
					message: 'Please enter valid e-mail',
					validateTrigger: 'onChange',
				},
			],
		},
		{
			title: 'Password',
			id: 'password',
			type: 'password',
			name: 'password',
			svg: <PasswordSvg />,
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please enter your password',
					validateTrigger: 'onChange',
				},
				// {
				// 	pattern:
				// 		/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s).{8,16}$/,
				// 	message:
				// 		'Password should have minimum 8 characters at least 1 uppercase, 1 lowercase, 1 number and 1 special character',
				// 	validateTrigger: 'onChange',
				// },
			],
		},
	],
	resetPassword: [
		{
			title: 'New Password',
			id: 'password',
			type: 'password',
			name: 'password',
			svg: <PasswordSvg />,
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please enter your password',
					validateTrigger: 'onChange',
				},
			],
		},
		{
			title: 'Confirm Password',
			id: 'confirmPassword',
			type: 'password',
			name: 'confirmPassword',
			svg: <PasswordSvg />,
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please enter your password again',
					validationTrigger: 'onChange',
				},
				({ getFieldValue }: any) => ({
					validator(_: any, value: any) {
						if (!value || getFieldValue('password') === value) {
							return Promise.resolve();
						}
						return Promise.reject(
							new Error('Passwords do not match')
						);
					},
					validateTrigger: 'onChange',
				}),
			],
		},
	],
	forgotPassword: [
		{
			title: 'Email Address',
			id: 'email',
			type: 'text',
			name: 'email',
			svg: <EmailSvg />,
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please enter your email address',
					validateTrigger: 'onChange',
				},
				{
					type: 'email',
					message: 'Please enter valid e-mail',
					validateTrigger: 'onChange',
				},
			],
		},
	],
	settingsMenuItems: [
		{
			key: 'integrations',
			icon: <IntegrationsSvg />,
			label: 'Integrations',
		},
		{
			key: 'commissions',
			icon: <SubscriptionSvg />,
			label: (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '8px',
					}}
				>
					<span style={{ lineHeight: '20px' }}>
						Common Commission
					</span>
					<span style={{ lineHeight: '20px' }}>Configurations</span>
				</div>
			),
		},
	],
	pageMenuItems: [
		{
			key: 'sales-reps',
			label: 'Sales Reps',
		},
		{
			key: 'sales-orders',
			label: 'Sales Orders',
		},
		{
			key: 'reports',
			label: 'Reports',
		},
	],
	inviteSalesRepsFields: [
		{
			title: 'First Name',
			id: 'firstName',
			type: 'text',
			name: 'firstName',
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please enter your first name',
					validateTrigger: 'onChange',
				},
				{
					min: 2,
					message: 'First name length must be minimum 2 characters',
					validateTrigger: 'onChange',
				},
				{
					max: 50,
					message:
						'First name length must be less than 50 characters',
					validateTrigger: 'onChange',
				},
				{ validator: validateNoOnlySpaces },
			],
		},
		{
			title: 'Last Name',
			id: 'lastName',
			type: 'text',
			name: 'lastName',
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please enter your last name',
					validateTrigger: 'onChange',
				},
				{
					min: 2,
					message: 'Last name length must be minimum 2 characters',
					validateTrigger: 'onChange',
				},
				{
					max: 50,
					message: 'Last name length must be less than 50 characters',
					validateTrigger: 'onChange',
				},
				{ validator: validateNoOnlySpaces },
			],
		},
		{
			title: 'Email Address',
			id: 'email',
			type: 'text',
			name: 'email',
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please enter email address',
					validateTrigger: 'onChange',
				},
				{
					type: 'email',
					message: 'Please enter valid email address',
					validateTrigger: 'onChange',
				},
			],
		},
		{
			title: 'Phone Number',
			id: 'phone',
			type: 'number',
			name: 'phone',
			placeHolder: '',
			required: false,
			rules: [
				// {
				// 	required: true,
				// 	message: 'Please enter phone number',
				// 	validateTrigger: 'onChange',
				// },
				{
					validator: phoneNumberValidator,
					message: 'Please enter valid phone number',
					validateTrigger: 'onChange',
				},
			],
		},
		{
			title: 'Date Of Joining',
			id: 'hubSpotCreatedAt',
			name: 'hubSpotCreatedAt',
			placeHolder: '',
			required: false,
		},
	],
	addRoleFields: [
		{
			title: 'Role Name',
			id: 'roleName',
			type: 'text',
			name: 'roleName',
			defaultValue: '',
			placeholder: 'Enter role name',
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please enter role name',
					validateTrigger: 'onChange',
				},
				{
					max: 50,
					message: 'Role name length must be less than 50 characters',
					validateTrigger: 'onChange',
				},
				{ validator: validateNoOnlySpaces },
			],
		},
		{
			title: 'Description',
			id: 'roleDescription',
			type: 'textArea',
			name: 'roleDescription',
			defaultValue: '',
			placeholder: 'Enter role description',
			placeHolder: '',
			required: true,
			rules: [
				{
					required: true,
					message: 'Please enter role description',
					validateTrigger: 'onSubmit',
				},
				{
					max: 200,
					message:
						'Role description length must be less than 200 characters',
					validateTrigger: 'onChange',
				},
				{ validator: validateNoOnlySpaces },
			],
		},
	],
	globalConstantItems: [
		{
			title: 'Global Overhead Ratio',
			id: 'globalOverHeadRatio',
			type: 'number',
			name: 'globalOverHeadRatio',
			placeHolder: '',
			required: false,
		},
		{
			id: 'creditCardFee',
			title: 'Credit Card Fee',
			type: 'number',
			name: 'creditCardFee',
			placeHolder: '',
			required: false,
		},
		{
			id: 'ebayFee',
			title: 'Ebay Fee',
			type: 'number',
			name: 'ebayFee',
			placeHolder: '',
			required: false,
		},
		{
			id: 'paypalFee',
			title: 'Paypal Fee',
			type: 'number',
			name: 'paypalFee',
			placeHolder: '',
			required: false,
		},
		{
			id: 'amazonFee',
			title: 'Amazon Fee',
			type: 'number',
			name: 'amazonFee',
			placeHolder: '',
			required: false,
		},
		{
			id: 'googlePayment',
			title: 'Google Payment',
			type: 'number',
			name: 'googlePayment',
			placeHolder: '',
			required: false,
		},
		{
			id: 'cashFee',
			title: 'Cash Fee',
			type: 'number',
			name: 'cashFee',
			placeHolder: '',
			required: false,
		},
		{
			id: 'checkFee',
			title: 'Check Fee',
			type: 'number',
			name: 'checkFee',
			placeHolder: '',
			required: false,
		},
		{
			id: 'wireTransferFee',
			title: 'Wire Tranfer Fee',
			type: 'number',
			name: 'wireTransferFee',
			placeHolder: '',
			required: false,
		},
		{
			id: 'newProductIntroduction',
			title: 'New Product',
			type: 'number',
			name: 'newProductIntroduction',
			placeHolder: '',
			required: false,
		},
	],
};

export const userColumns = [
	{
		title: 'Organization Name',
		dataIndex: 'name',
		key: 'name',
		sorter: (a: any, b: any) => {
			return a.name.length - b.name.length;
		},
		sortDirections: ['descend'],
	},
	{
		title: 'Email Address',
		dataIndex: 'email',
		key: 'email',
	},
	{
		title: 'Phone number',
		dataIndex: 'phone',
		key: 'phone',
	},
	{
		title: 'Role',
		dataIndex: 'role',
		key: 'role',
	},
	{
		title: 'Action',
		dataIndex: 'action',
		key: 'action',
	},
];

export const roleDataSource: any = [];
roleDataSource.push({
	name: `Admin`,
	description: `Description for role`,
	permissions: `Permission Details`,
	status: true,
	action: `some action`,
});

for (let index = 0; index < 15; index++) {
	roleDataSource.push({
		name: `Role ${index}`,
		description: `Description for role ${index}`,
		permissions: `Permission Details`,
		status: index % 2 == 0 ? true : false,
		action: `some action`,
	});
}

export const roleColumns = [
	{
		title: 'Role Name',
		dataIndex: 'name',
		key: 'name',
		sorter: (a: any, b: any) => {
			return a.name.length - b.name.length;
		},
		sortDirections: ['descend'],
	},
	{
		title: 'Description',
		dataIndex: 'description',
		key: 'description',
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
	},
	{
		title: 'Permissions',
		dataIndex: 'permissions',
		key: 'permissions',
	},
	{
		title: 'Action',
		dataIndex: 'action',
		key: 'action',
	},
];

export const UserProfileData = [
	{
		title: 'First Name',
		id: 'firstName',
		type: 'text',
		name: 'firstName',
		defaultValue: '',
		disabled: false,
		errorMessage: 'Please enter your first name',
		required: true,
		rules: [
			{
				required: true,
				message: 'Please enter your first name',
				validateTrigger: 'onChange',
			},
			{
				min: 2,
				max: 40,
				message: 'First name must be between 2 and 40 characters',
				validateTrigger: 'onChange',
			},
		],
	},
	{
		title: 'Last Name',
		id: 'lastName',
		type: 'text',
		name: 'lastName',
		defaultValue: '',
		disabled: false,
		errorMessage: 'Please enter your last name',
		required: true,
		rules: [
			{
				required: true,
				message: 'Please enter your last name',
				validateTrigger: 'onChange',
			},
			{
				min: 2,
				max: 40,
				message: 'Last name must be between 2 and 40 characters',
				validateTrigger: 'onChange',
			},
		],
	},
	{
		title: 'Email Address',
		id: 'email',
		type: 'text',
		name: 'email',
		defaultValue: '',
		disabled: true,
		errorMessage: 'Please enter your email',
		required: false,
		rules: [],
	},
	{
		title: 'Phone Number',
		id: 'phone',
		type: 'number',
		name: 'phone',
		required: false,
		rules: [
			{
				validator: phoneNumberValidator,
				message: 'Please enter valid phone number',
				validateTrigger: 'onChange',
			},
		],
	},
];

export const APIKey = [
	{
		title: 'API Key',
		id: 'apiKey',
		type: 'text',
		name: 'apiKey',
		defaultValue: '',
		disabled: false,
		errorMessage: 'Please enter API key',
		required: true,
		rules: [
			{
				required: true,
				message: 'Please enter API key',
				validateTrigger: 'onChange',
			},
			{
				min: 10,
				message: 'API key length must contain at least 10 characters',
				validateTrigger: 'onChange',
			},
			{
				max: 150,
				message: 'API key length must be less than 150 characters',
				validateTrigger: 'onChange',
			},
		],
	},
];

export const monthNames = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const months = [
	{ value: 1, label: 'January' },
	{ value: 2, label: 'February' },
	{ value: 3, label: 'March' },
	{ value: 4, label: 'April' },
	{ value: 5, label: 'May' },
	{ value: 6, label: 'June' },
	{ value: 7, label: 'July' },
	{ value: 8, label: 'August' },
	{ value: 9, label: 'September' },
	{ value: 10, label: 'October' },
	{ value: 11, label: 'November' },
	{ value: 12, label: 'December' },
];                                                                                                                                                                                                                                         
