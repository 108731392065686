import { configureStore } from '@reduxjs/toolkit';
import profileSlice from './slices/profileSlice';
import AuthSlice from './slices/authSlice';
import SalesRepsSlice from './slices/salesRepsSlice';
import GlobalConstantSlice from './slices/globalConstantSlice';
import ConnectionsSlice from './slices/connectionSlice';

const store = configureStore({
	reducer: {
		userProfile: profileSlice.reducer,
		auth: AuthSlice.reducer,
		salesReps: SalesRepsSlice.reducer,
		globalConstants: GlobalConstantSlice.reducer,
		connections: ConnectionsSlice.reducer,
	},
});

export default store;
export type AppDispatch = typeof store.dispatch;
