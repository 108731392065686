import { createSlice } from '@reduxjs/toolkit';
import { getGlobalConstantsAction } from '../../redux/actions/globalConstant';

const initialState: any = {
	data: null,
	isLoading: true,
	error: null,
};

const GlobalConstantSlice = createSlice({
	name: 'globalConstants',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getGlobalConstantsAction.pending, (state) => {
			state.isLoading = true;

			state.error = null;
		});
		builder.addCase(getGlobalConstantsAction.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action?.payload?.data;
		});
		builder.addCase(
			getGlobalConstantsAction.rejected,
			(state, action: any) => {
				state.isLoading = false;
				state.error = action.payload;
			}
		);
	},
});

export default GlobalConstantSlice;
