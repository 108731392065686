import { Col, Form, Input, Modal, Row, Image } from 'antd';
import { useState } from 'react';

import styles from './index.module.scss';
import Buttons from '../Buttons';
import { APIKey } from 'constants/Data';

import { toastText } from 'utils/utils';
import { postApi } from 'apis';
// import { AppDispatch } from 'redux/store';
// import { useDispatch } from 'react-redux';

const APIKeyModal = (props: any) => {
	const [isLoading, setIsLoading] = useState(false);
	// const dispatch = useDispatch<AppDispatch>();
	const myButtons = [
		{
			text: 'Connect',
			isLoading: isLoading,
			className: 'primary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: true,
			onclick: () => {
				console.log('Clicked first');
			},
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => {
				handleCancel();
			},
		},
	];
	const handleSubmit = async (values: any): Promise<void> => {
		try {
			setIsLoading(true);
			const data = {
				hapikey: values.apiKey,
			};
			const response = await postApi('/hubspot/connect', data);
			if (response.data.responseStatus === 200) {
				toastText(response.data.message, 'success');
			} else {
				toastText(response.data.message, 'error');
			}
			handleCancel();
		} catch (error: any) {
			toastText(error.response.data.message, 'error');
		} finally {
			setIsLoading(false);
		}
	};
	const handleCancel = () => {
		props.setKeyModalVisible(false);
	};
	return (
		<Modal
			open={props.keyModalVisible}
			onOk={handleSubmit}
			onCancel={handleCancel}
			okText={'Save'}
			closable={false}
			footer={null}
			className="apiKey_popup"
		>
			<Form
				name="basic"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				style={{ maxWidth: 600 }}
				onFinish={handleSubmit}
				autoComplete="off"
			>
				<div className={styles['center']}>
					<Image
						className={styles['image_hubspot']}
						src="/assets/images/hubspot.png"
						preview={false}
					/>
				</div>
				<div className={styles['profile-modal__body']}>
					<Row gutter={24}>
						{APIKey?.map((item: any, index: number) => (
							<Col
								className="gutter-row fields"
								xs={24}
								sm={24}
								md={24}
								key={index}
								lg={24}
							>
								{
									<>
										<label
											className={
												styles['profile-form-label']
											}
										>
											{item?.title}{' '}
											{item?.required && (
												<span className="required-color">
													*
												</span>
											)}
										</label>
										<Form.Item
											className="formItem"
											name={item?.name}
											rules={item?.rules as []}
											wrapperCol={{ span: 24 }}
										>
											<Input
												maxLength={150}
												size="large"
												disabled={item?.disabled}
												type={item?.type}
												// defaultValue={
												// 	userProfileData[item?.name]
												// }
											/>
										</Form.Item>
									</>
								}
							</Col>
						))}
					</Row>
				</div>
				<hr />
				<div className={styles['profile_modal_footer']}>
					<Buttons buttons={myButtons}></Buttons>
				</div>
			</Form>
		</Modal>
	);
};
export default APIKeyModal;
