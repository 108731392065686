import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApi, postApi } from 'apis';

export const inviteSalesRepsAction = createAsyncThunk(
	'sales-rep/invite-salesrep',
	async (data: object, { rejectWithValue }) => {
		try {
			const response = await postApi('/sales-rep/invite-salesrep', data);
			return response.data;
		} catch (error: any) {
			if (!error.response) {
				throw error;
			}
			return rejectWithValue(error?.response?.data);
		}
	}
);

export const getAllSalesRepsAction = createAsyncThunk(
	'sales-rep/getAll-salesrep',
	async (_, { rejectWithValue }) => {
		try {
			const response = await getApi('/sales-rep');
			return response.data;
		} catch (error: any) {
			if (!error.response) {
				throw error;
			}
			return rejectWithValue(error?.response?.data);
		}
	}
);

export const getSalesRepDetailsAction = createAsyncThunk(
	'sales-rep/get-salesrep-details',
	async (data: any, { rejectWithValue }) => {
		const query: any = {};
		if (data.salesRepId) {
			query['salesRepId'] = data.salesRepId;
		}
		if (data.page) {
			query['page'] = data.page;
		}
		if (data.limit) {
			query['limit'] = data.limit;
		}
		try {
			const response = await getApi('/sales-rep/details', query);
			return response.data;
		} catch (error: any) {
			if (!error.response) {
				throw error;
			}
			return rejectWithValue(error?.response?.data);
		}
	}
);
