import { Header, Sidebar } from 'components/Global';
import { FORMDATA } from 'constants/Data';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { RoleContext } from 'components/Global/AuthLayout';
import Notification from 'components/Global/Notification';

const GlobalLayout = () => {
	// inits
	const { pageMenuItems } = FORMDATA;

	const { pathname } = useLocation();

	const [finalMenuItems, setMenuItems] = useState<any>([]);
	const [isLoading] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const roleContext = useContext(RoleContext);

	console.log('Login: ', isLoggedIn);

	const navigate = useNavigate();

	useEffect(() => {
		let menuItems;
		if (roleContext.role === 'ADMIN') {
			menuItems = pageMenuItems;
		} else {
			menuItems = pageMenuItems?.filter(
				(item) => item.key === 'sales-reps' || item.key === 'reports'
			);
		}
		setMenuItems(menuItems);
	}, [pageMenuItems, roleContext.role]);

	const [selectedSidebar, setSelectedSidebar] = useState<string>('dashboard');

	const handleSidebar = (sidebarProps: any) => {
		if (sidebarProps?.key === 'sales-reps') {
			setSelectedSidebar('/');
			navigate(`/`);
		} else {
			setSelectedSidebar(sidebarProps?.key);
			navigate(`/${sidebarProps?.key}`);
		}
	};

	const initialFunctionCall = () => {
		if (pathname === '/sales-reps') {
			setSelectedSidebar('/');
		} else if (pathname === '/sales-orders') {
			setSelectedSidebar('sales-orders');
		} else if (pathname === '/reports') {
			setSelectedSidebar('reports');
		} else {
			setSelectedSidebar('sales-reps');
		}
	};

	useEffect(() => {
		initialFunctionCall();
	}, [pathname]);

	useEffect(() => {
		const token = localStorage.getItem('accessToken');
		if (token) {
			setIsLoggedIn(true);
		} else {
			setIsLoggedIn(false);
			navigate('/login');
		}
	}, []);

	// JSX
	return !isLoading ? (
		<div className={styles['global-layout']}>
			<div className={styles['global-layout__wrapper']}>
				<div className={styles['global-layout__header']}>
					<Header />
				</div>
				<div className={styles['global-layout__body']}>
					<div className={styles['global-layout__body--sidebar']}>
						<Sidebar
							items={finalMenuItems}
							isGetSupportButton={true}
							selectedSidebar={selectedSidebar}
							handleSidebar={handleSidebar}
						/>
					</div>
					<div className={styles['global-layout__body--body']}>
						<Outlet />
					</div>
				</div>
			</div>
			<Notification />
		</div>
	) : null;
};

export default GlobalLayout;
