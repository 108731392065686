import { DatePicker, Form, Input } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import PasswordValidation from '../PasswordValidation';
import styles from './index.module.scss';
import { singleUserInputInputWithLabelAndSvgProps } from './types';
// import { formatPhoneNumber } from 'utils/utils';

// For create the dynamic input component
const InputWithLabelAndSvg: FC<singleUserInputInputWithLabelAndSvgProps> = (
	props
) => {
	// Inits
	const { singleUserInput, disabled, focus, setIsPasswordValidate } = props;

	const inputRef = useRef<HTMLInputElement>(null);

	const initialCall = () => {
		if (focus && inputRef.current) {
			inputRef.current.focus();
		}
	};

	useEffect(() => {
		initialCall();
	}, [inputRef]);

	const [passwordValidations, setPasswordValidations] = useState<{
		minLength?: boolean;
		hasSpecialAndNumber?: boolean;
	}>({});

	const handlePasswordChange = (password: string) => {
		const isValidLength = password.length >= 8 && password.length <= 16;
		const hasSpecialAndNumber =
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]*$/.test(
				password
			);

		setIsPasswordValidate(isValidLength && hasSpecialAndNumber);
		setPasswordValidations({
			minLength: isValidLength,
			hasSpecialAndNumber: hasSpecialAndNumber,
		});
	};

	// JSX
	return (
		<div className={styles['input-icon']}>
			{singleUserInput.id !== 'phone' ? (
				<>
					<div className={styles['input-icon__title']}>
						{singleUserInput.svg && (
							<div className={styles['input-icon__title--svg']}>
								{singleUserInput.svg}
							</div>
						)}
						<label className={styles['input-icon__title--label']}>
							{singleUserInput.title}{' '}
							{singleUserInput?.required && (
								<span className="required-color">*</span>
							)}
						</label>
					</div>
					<div className={styles['input-icon__form']}>
						<Form.Item
							name={singleUserInput.name}
							rules={singleUserInput.rules}
						>
							{(singleUserInput.type === 'text' ||
								singleUserInput.type === 'number') && (
								<Input
									placeholder={singleUserInput.placeHolder}
									size="large"
									className={
										styles['input-icon__form--input']
									}
									type={singleUserInput.type}
									disabled={disabled ? disabled : false}
									ref={inputRef as any}
								/>
							)}

							{singleUserInput.type === 'password' && (
								<div>
									<Input.Password
										placeholder={
											singleUserInput.placeHolder
										}
										size="large"
										className={
											styles['input-icon__form--input']
										}
										onChange={(e) =>
											handlePasswordChange(e.target.value)
										}
									/>
								</div>
							)}
							{singleUserInput.id === 'hubSpotCreatedAt' && (
								<DatePicker
									size="large"
									style={{ width: '100%' }}
									format="MM/DD/YYYY"
								/>
							)}
						</Form.Item>
						{singleUserInput.id === 'password' && (
							<div>
								<PasswordValidation
									minLength={passwordValidations.minLength}
									hasSpecialAndNumber={
										passwordValidations.hasSpecialAndNumber
									}
								/>
							</div>
						)}
					</div>
				</>
			) : (
				``
			)}
			{/* {singleUserInput.id === 'phone' && (
				<>
					<div className={styles['input-icon__title']}>
						{singleUserInput.svg && (
							<div className={styles['input-icon__title--svg']}>
								{singleUserInput.svg}
							</div>
						)}
						<label className={styles['input-icon__title--label']}>
							{singleUserInput.title}{' '}
							{singleUserInput?.required && (
								<span className="required-color">*</span>
							)}
						</label>
					</div>
					<Form.Item
						rules={singleUserInput.rules}
						// name={singleUserInput.name}
					>
						<Input
							placeholder={singleUserInput.placeHolder}
							size="large"
							className={styles['input-icon__form--input']}
							onBlur={() => {
								setPhoneNumberFocused(true);
							}}
							onChange={(e) => phoneChangeHandler(e.target.value)}
							value={phoneNumber}
							maxLength={14}
						/>
						{isPhoneNumberFocused && phoneNumber?.length === 0 && (
							<p className="error-message">
								Please enter your number
							</p>
						)}
						{isPhoneNumberFocused &&
							phoneNumberWithoutUsFormat?.length !== 10 &&
							phoneNumberWithoutUsFormat?.length !== 0 && (
								<p className="error-message">
									Please enter a valid phone number
								</p>
							)}
					</Form.Item>
				</>
			)} */}
		</div>
	);
};

export default InputWithLabelAndSvg;
