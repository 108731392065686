import { Col, Row } from 'antd';
import SalesOrdersTable from './Table';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { useEffect } from 'react';
import { getAllSalesRepsAction } from '../../redux/actions/salesRepsAction';

export const SalesOrdersReport = () => {
	const dispatch = useDispatch<AppDispatch>();
	useEffect(() => {
		dispatch(getAllSalesRepsAction());
	}, []);

	return (
		<div>
			<Row justify="center">
				{/* <Col xs={24} sm={24} md={24} lg={24}>
					<SalesOrdersHeader allSalesRep={allSalesRep} />
				</Col> */}
				<Col xs={24} sm={24} md={24} lg={24}>
					<SalesOrdersTable />
				</Col>
			</Row>
		</div>
	);
};
