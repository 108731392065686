import { Form, Input } from 'antd';

import InputWithLabelAndSvg from 'components/Global/InputWithLabel';
import { FORMDATA } from 'constants/Data';
import React, { FC, useState } from 'react';
import styles from './index.module.scss';
import { postApi } from 'apis';
import { phoneNumberFormatHandler, toastText } from 'utils/utils';
import { SideDrawerBodyProps } from './types';
import Buttons from 'components/Global/Buttons';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { getAllSalesRepsAction } from '../../../redux/actions/salesRepsAction';

const InviteSalesRep: FC<SideDrawerBodyProps> = (props) => {
	const { inviteSalesRepsFields } = FORMDATA;
	const [isLoading, setIsLoading] = useState(false);

	const myButtons = [
		{
			text: 'Invite',
			isLoading: isLoading,
			className: 'primary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: true,
			onclick: () => {
				console.log('Clicked first');
			},
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			onclick: () => {
				closeDrawerByAnimation();
			},
		},
	];

	const { closeDrawerByAnimation } = props;
	const [phoneNumberWithoutUsFormat, setPhoneNumberWithoutUsFormat] =
		useState<any>('');
	const [phoneNumber, setPhoneNumber] = useState<any>(
		phoneNumberFormatHandler('') || ''
	);
	const [isPhoneNumberFocused, setPhoneNumberFocused] = useState(false);

	const dispatch = useDispatch<AppDispatch>();

	const onFinish = async (values: any) => {
		const data = { ...values };

		if (values.hubSpotCreatedAt && values.hubSpotCreatedAt.$d) {
			data.hubSpotCreatedAt = values.hubSpotCreatedAt.$d;
		} else {
			delete data['hubSpotCreatedAt'];
		}

		setPhoneNumberFocused(true);

		if (phoneNumberWithoutUsFormat?.length > 0) {
			if (phoneNumberWithoutUsFormat?.length === 10) {
				const inputPhoneNumber = phoneNumber.replace(/\D/g, '');
				data.phone = inputPhoneNumber;
				try {
					setIsLoading(true);
					const response = await postApi(
						'/sales-rep/invite-salesrep',
						data
					);
					toastText(response.data.message, 'success');
					closeDrawerByAnimation();
				} catch (error: any) {
					toastText(error.response.data?.message, 'error');
				} finally {
					setIsLoading(false);
				}
			}
		} else {
			try {
				setIsLoading(true);
				const response = await postApi(
					'/sales-rep/invite-salesrep',
					data
				);
				if (response.data.responseStatus === 200) {
					toastText(response.data.message, 'success');
					closeDrawerByAnimation();
				}
			} catch (error: any) {
				toastText(error.response.data?.message, 'error');
			} finally {
				setIsLoading(false);
			}
		}
		dispatch(getAllSalesRepsAction());
	};

	// For handle the phone number change
	const phoneChangeHandler = (phone: any) => {
		const normalNumber = phone.replace(/\D/g, '');
		if (normalNumber?.length <= 10) {
			const phoneNo = phoneNumberFormatHandler(phone);
			setPhoneNumberWithoutUsFormat(normalNumber);

			setPhoneNumber(phoneNo);
		}
	};

	// JSX
	return (
		<div className={styles['side-drawer-body']}>
			<Form
				name="basic"
				onFinish={onFinish}
				// onFinishFailed={onFinishFailed}
				autoComplete="off"
				layout="vertical"
				labelAlign="left"
				className={styles['side-drawer-form']}
			>
				<div className={styles['side-drawer-form__inputs']}>
					{inviteSalesRepsFields.map((singleField: any, index) => {
						return (
							<React.Fragment key={index}>
								{singleField.name == 'phone' ? (
									<div>
										<div
											className={
												styles['input-icon__title']
											}
										>
											{singleField.svg && (
												<div
													className={
														styles[
															'input-icon__title--svg'
														]
													}
												>
													{singleField.svg}
												</div>
											)}
											<label
												className={
													styles[
														'input-icon__title--label'
													]
												}
											>
												{singleField.title}{' '}
												{singleField?.required && (
													<span className="required-color">
														*
													</span>
												)}
											</label>
										</div>
										<Form.Item
											className="formItem"
											// name={item?.name}
											// rules={item?.rules as []}
											wrapperCol={{ span: 24 }}
										>
											<Input
												size="large"
												// disabled={item?.disabled}
												value={phoneNumber}
												onBlur={() => {
													setPhoneNumberFocused(true);
												}}
												onChange={(e) =>
													phoneChangeHandler(
														e.target.value
													)
												}
											/>
											{isPhoneNumberFocused &&
												phoneNumberWithoutUsFormat?.length !==
													10 &&
												phoneNumberWithoutUsFormat?.length !==
													0 && (
													<p className="error-message">
														Please enter a valid
														phone number
													</p>
												)}
										</Form.Item>
									</div>
								) : (
									<InputWithLabelAndSvg
										singleUserInput={singleField}
										focus={index === 0 ? true : false}
									/>
								)}
							</React.Fragment>
						);
					})}
				</div>
				<div className={styles['side-drawer-form__buttons']}>
					<Buttons buttons={myButtons}></Buttons>
					{/* <Form.Item>
						<Button
							className={`${
								styles['side-drawer-form__buttons--save']
							} ${isLoading && 'pointer-event-none'}`}
							htmlType="submit"
						>
							{isLoading ? (
								<img
									src="/assets/gifs/loading-black.gif"
									height={40}
								/>
							) : (
								'Save'
							)}
						</Button>
					</Form.Item>
					<Form.Item>
						<Button
							className={`${styles['side-drawer-form__buttons--cancel']}`}
							onClick={closeDrawerByAnimation}
							disabled={isLoading}
							htmlType="button"
						>
							Cancel
						</Button>
					</Form.Item> */}
				</div>
			</Form>
		</div>
	);
};

export default InviteSalesRep;
