import { Col, DatePicker, Row, Select, Table, Tooltip } from 'antd';
import { getApi, getApiExcel } from 'apis';
import { RoleContext } from 'components/Global/AuthLayout';
import Export from 'components/Global/Export';
import { months } from 'constants/Data';
import dayjs from 'dayjs';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { invalidText, toastText } from 'utils/utils';
import DetailCommissionModal from '../Modal';
import styles from './index.module.scss';
import './index.scss';

const SalesReportTable = () => {
	const { Column } = Table;

	const date = new Date();

	const [queryParams] = useSearchParams();

	const allSalesRep = useSelector((state: any) => state.salesReps.data);
	const user = useSelector((state: any) => state.userProfile.data);

	const roleContext = useContext(RoleContext);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isExportLoading, setIsExportLoading] = useState(false);
	const [salesRepId, setSalesRepId] = useState(
		queryParams.get('salesRepId') || null
	);
	const [selectedSalesRep, setSelectedSalesRep] = useState('');

	const [isLoading, setIsLoading] = useState(false);
	const [salesRepOptions, setSalesRepOptions] = useState([]);

	// const [totalRecords, setTotalRecords] = useState(0);
	const [year, setYear] = useState(
		queryParams.get('year')
			? Number(queryParams.get('year'))
			: date.getFullYear()
	);

	const [month, setMonth] = useState(
		queryParams.get('month')
			? Number(queryParams.get('month'))
			: date.getMonth() + 1
	);
	const [commissionType, setCommissionType] = useState('');
	const [reportData, setReportData] = useState([]);
	const [detailedData, setDetailedData] = useState();
	// const [totalRecords, setTotalRecords] = useState(0);
	// const [currentPage, setCurrentPage] = useState(1);
	// const [pageSize, setPageSize] = useState(10);

	const exportPdf = async () => {
		try {
			setIsExportLoading(true);
			const response: any = await getApiExcel(
				'/reports/detailed-commission-report/pdf',
				{
					salesRepId: salesRepId,
					month: month,
					year: year,
					commissionType: commissionType,
				}
			);

			const fileName =
				moment(new Date()).format('MM-DD-YYYY_hh-mm-ss') +
				`_${user.firstName} ${user.lastName}-detailed-commission-report.pdf`;

			const pdfBlob = new Blob([response.data], {
				type: 'application/pdf',
			});
			const pdfUrl = URL.createObjectURL(pdfBlob);
			const link = document.createElement('a');
			link.href = pdfUrl;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (err: any) {
			if (err.response.status === 400) {
				toastText(
					'No report has been generated for this time period',
					'error'
				);
			} else {
				toastText(
					'Something went wrong in exporting pdf report.',
					'error'
				);
			}
		} finally {
			setIsExportLoading(false);
		}
	};

	const exportExcel = async () => {
		try {
			setIsExportLoading(true);
			const response: any = await getApiExcel(
				'/reports/detailed-commission-report/excel',
				{
					salesRepId: salesRepId,
					month: month,
					year: year,
					commissionType: commissionType,
				}
			);

			const fileName =
				moment(new Date()).format('MM-DD-YYYY_hh-mm-ss') +
				`_${user.firstName} ${user.lastName}-detailed-commission-report.xlsx`;

			const excelBlob = new Blob([response.data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});
			const excelUrl = URL.createObjectURL(excelBlob);
			const link = document.createElement('a');
			link.href = excelUrl;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (err: any) {
			if (err.response.status === 400) {
				toastText(
					'No report has been generated for this time period',
					'error'
				);
			} else {
				toastText(
					'Something went wrong in exporting excel report.',
					'error'
				);
			}
		} finally {
			setIsExportLoading(false);
		}
	};

	const getDetailedReport = async () => {
		try {
			setIsLoading(true);
			const response = await getApi(
				'/reports/detailed-commission-report',
				{
					salesRepId: salesRepId,
					month: month,
					year: year,
					commissionType: commissionType,
					// page: currentPage,
					// limit: pageSize,
				}
			);
			setReportData(response.data.data);
			// setTotalRecords(response.data.total);
		} catch (err) {
			toastText('Something went wrong in fetching the report.', 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const modalOpenHandler = (data: any) => {
		setIsModalOpen(true);
		// setIsModalOpen(false);
		setDetailedData(data);
	};

	const cancelHandler = () => {
		setIsModalOpen(false);
	};

	const getSalesRepId = (salesRepId: any) => {
		setSalesRepId(salesRepId);
	};

	const getMonth = (month: any) => {
		setMonth(month);
	};

	const handleCommissionType = (type: string) => {
		setCommissionType(type);
	};

	// const paginationChangeHandler = (value: number) => {
	// 	setCurrentPage(value);
	// };

	// const pageSizeHandler = (currentPage: number, pageSize: any) => {
	// 	// setCurrentPage(currentPage);
	// 	setPageSize(pageSize);
	// };

	useEffect(() => {
		if (allSalesRep && allSalesRep.length > 0) {
			const data = allSalesRep.filter((rep: any) => !rep.isInvited);
			setSalesRepOptions(data);
			setSalesRepId(data[0]?.id);
			setSelectedSalesRep(`${data[0]?.firstName} ${data[0]?.lastName}`);
		}
	}, [allSalesRep]);

	useEffect(() => {
		if (salesRepId) {
			getDetailedReport();
		}
		// }, [year, month, salesRepId, commissionType, currentPage, pageSize]);
	}, [year, month, salesRepId, commissionType]);

	return (
		<div>
			<div>
				<Row justify="center">
					<Col xs={24} sm={24} md={24} lg={24}>
						<div className={styles['sales-report']}>
							<div className={styles['sales-report__date']}>
								<DatePicker
									picker="year"
									format="YYYY"
									style={{ width: 120 }}
									disabledDate={(current) => {
										return (
											current &&
											(current > moment().endOf('year') ||
												current <
													moment()
														.subtract(4, 'years')
														.startOf('year'))
										);
									}}
									onChange={(selectedYear) => {
										setYear(selectedYear.year());
									}}
									value={dayjs().year(year)}
									allowClear={false}
								/>
								<Select
									showSearch
									placeholder="Select month"
									options={months.map((month) => ({
										value: month.value,
										label: month.label,
									}))}
									style={{ width: 120 }}
									onChange={(month) => {
										getMonth(month);
									}}
									value={month}
									optionFilterProp="label"
								/>
								{roleContext.role === 'ADMIN' && (
									<div>
										<span>Sales Rep : </span>
										<Select
											showSearch
											style={{ width: 180 }}
											placeholder="Select Sales Rep"
											value={salesRepId}
											options={salesRepOptions.map(
												(salesRep: any) => ({
													value: salesRep.id,
													label: `${salesRep.firstName} ${salesRep.lastName}`,
												})
											)}
											onChange={(
												salesRepId,
												data: any
											) => {
												getSalesRepId(salesRepId);
												setSelectedSalesRep(data.label);
											}}
											optionFilterProp="label"
										/>
									</div>
								)}
								<div>
									<span>Commission : </span>
									<Select
										showSearch
										defaultValue=""
										onChange={(type) => {
											handleCommissionType(type);
										}}
										options={[
											{
												value: '',
												label: 'All Commissions',
											},
											{
												value: 'INSIDE',
												label: 'Inside Commission',
											},
											{
												value: 'OUTSIDE',
												label: 'Outside Commission',
											},
											{
												value: 'SPECIAL_PRODUCT',
												label: 'Special/Intermediate Commission',
											},
											{
												value: 'RETENTION',
												label: 'Retention Commission',
											},
										]}
										style={{ width: 200 }}
										optionFilterProp="label"
									/>
								</div>
							</div>

							<Export
								handleExport={(type: string) => {
									if (type === 'pdf') {
										exportPdf();
									}
									if (type === 'xls') {
										exportExcel();
									}
								}}
								isLoading={isExportLoading}
							/>
						</div>
					</Col>
					<Col xs={24} sm={24} md={24} lg={24}>
						<Table
							loading={isLoading}
							dataSource={reportData}
							pagination={false}
							// pagination={{
							// 	total: totalRecords,
							// 	current: currentPage,
							// 	onChange: paginationChangeHandler,
							// 	pageSize: pageSize,
							// 	showSizeChanger: true,
							// 	onShowSizeChange: pageSizeHandler,
							// 	pageSizeOptions: [10, 20, 50, 100, 200],
							// }}
							scroll={{ y: 'calc(100vh - 310px)' }}
							rowClassName={(record: any) => {
								if (record.isTotal) {
									return 'total';
								}
								if (
									record.isSubTotal &&
									commissionType === 'OUTSIDE'
								) {
									return 'sub-total';
								}
								if (
									record.isSubTotal &&
									commissionType !== 'OUTSIDE'
								) {
									return 'default-sub-total';
								}
								if (
									record.isOutsideAdjust &&
									commissionType === 'OUTSIDE'
								) {
									return 'outside-adjust';
								}

								return '';
							}}
						>
							<Column
								title="No."
								dataIndex="sr"
								key="sr"
								className="bg-white"
								render={(value, data: any, index) => {
									return data.isSubTotal ||
										data.isTotal ||
										data.isOutsideAdjust ? (
										''
									) : (
										<p>{`${index + 1}`}</p>
									);
								}}
								width={80}
							/>

							<Column
								title="Date"
								dataIndex="date"
								key="date"
								className="bg-white"
								width={120}
							/>
							<Column
								title="Customer"
								dataIndex="customer"
								key="customer"
								className="bg-white"
								width={150}
							/>
							<Column
								title="QBO Invoice"
								dataIndex="qboInvoice"
								key="qboInvoice"
								className="bg-white"
								width={130}
								render={(text, data: any) => {
									return (
										text && (
											<a
												className="text-color"
												href={`https://app.sandbox.qbo.intuit.com/app/invoice?txnId=${text?.replace(
													/\D/g,
													''
												)}`}
												target="_blank"
												rel="noopener noreferrer"
											>
												{data.qboInvoiceDoc}
											</a>
										)
									);
								}}
							/>
							<Column
								title="EES Order"
								dataIndex="eesOrder"
								key="eesOrder"
								className="bg-white"
								width={120}
								render={(text, record: any) =>
									record.eesOrder === 'Total' ? (
										<p>{text}</p>
									) : record.isSubTotal ||
									  record.isOutsideAdjust ? (
										''
									) : (
										<a className="text-color">
											{invalidText(text) ? '-' : text}
										</a>
									)
								}
							/>
							<Column
								title="Order Amount"
								dataIndex="amount"
								key="amount"
								className="bg-white"
								width={120}
								render={(text) => (
									<p>{text != null ? `$${text}` : ''}</p>
								)}
							/>

							<Column
								title="Commission Type"
								dataIndex="commissionType"
								key="commissionType"
								className="bg-white"
								width={150}
								render={(text) => (
									<p>{text != null ? `${text}` : ''}</p>
								)}
							/>
							<Column
								title="Commission Amount"
								dataIndex="commissionAmount"
								key="commissionAmount"
								className="bg-white"
								width={150}
								render={(text, data: any) => (
									<p>
										<p>
											{data.isOutsideAdjust
												? text != null
													? text
													: ''
												: text != null
												? `$${text}`
												: '$0.00'}
										</p>
									</p>
								)}
							/>
							<Column
								title="Deductions (if any)"
								dataIndex="deductions"
								key="deductions"
								className="bg-white"
								width={120}
								render={(text, data: any) => (
									<p>
										{text != null && !data.isOutsideAdjust
											? `$${text}`
											: data.isSubTotal ||
											  data.isOutsideAdjust
											? ''
											: '$0.00'}
									</p>
								)}
							/>
							{/* <Column
								title="Fees"
								dataIndex="fees"
								key="fees"
								className="bg-white"
								width={120}
								render={(text, data: any) => {
									return (
										<p>
											{text != null
												? `$${text}`
												: data.isSubTotal ||
												  data.isOutsideAdjust
												? ''
												: '$0.00'}
										</p>
									);
								}}
							/> */}
							<Column
								title="Payouts Amount"
								dataIndex="payoutsAmount"
								key="payoutsAmount"
								className="bg-white"
								width={130}
								// render={(text) => <span>${text}</span>}
								render={(value, data: any) => {
									return data.isSubTotal ||
										data.eesOrder === 'Total' ||
										data.isOutsideAdjust ? (
										<p className="">
											${data.payoutsAmount}
										</p>
									) : (
										<Tooltip
											title="Details"
											className="pointer"
										>
											<p
												onClick={() =>
													modalOpenHandler(data)
												}
												style={{ color: '#286fd1' }}
											>
												${data.payoutsAmount}
											</p>
										</Tooltip>
									);
								}}
							/>
							<Column
								title="Payout Date"
								dataIndex="payoutDate"
								key="payoutDate"
								className="bg-white"
								width={120}
							/>
						</Table>
					</Col>
				</Row>
			</div>
			{isModalOpen && (
				<DetailCommissionModal
					isOpen={isModalOpen}
					handleCancel={cancelHandler}
					detailedData={detailedData}
					selectedSalesRep={selectedSalesRep}
				/>
			)}
		</div>
	);
};

export default SalesReportTable;
