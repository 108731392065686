import { getApi } from 'apis';
import Cards from 'components/Global/Cards';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getConnectionsAction } from '../../../redux/actions/connectionsAction';
import { AppDispatch } from 'redux/store';
// import { useNavigate } from 'react-router-dom';
import ConnectionTable from 'components/Settings/Integrations/ConnectionTable';
import APIKeyModal from 'components/Global/APIKeyModal';

export default function IntegrationPage() {
	// const navigate = useNavigate();

	const dispatch = useDispatch<AppDispatch>();

	const connectionData = useSelector(
		(state: any) => state?.connections?.data
	);

	// const [loadingHubSpot, setLoadingHubSpot] = useState(false);
	const [loadingQuickbooks, setLoadingQuickbooks] = useState(false);
	const [isAPIKeyModalOpen, setIsAPIKeyModalOpen] = useState(false);

	const isHubSpotIntegrated = connectionData?.some(
		(connection: any) => connection.channelType === 'HUB_SPOT'
	);
	const isQuickbooksIntegrated = connectionData?.some(
		(connection: any) => connection.channelType === 'QBO'
	);

	const getConnections = async () => {
		await dispatch(getConnectionsAction());
	};

	const qboAuth = async () => {
		setLoadingQuickbooks(true);
		try {
			const response = await getApi('/qbo/auth-url');
			if (response.data.responseStatus === 200) {
				window.open(response.data?.authUri, '_self');
				getConnections();
				setLoadingQuickbooks(false);
			}
		} catch (error: any) {
			console.log('error: ', error);
		}
	};

	const hubSpotAuth = async () => {
		// setLoadingHubSpot(true);
		setIsAPIKeyModalOpen(true);
	};
	const myCards = [
		{
			id: 'hubspot',
			buttonText: isHubSpotIntegrated
				? 'Connected'
				: 'Connect to HubSpot',
			logo: '/assets/images/hubspot.png',
			ghost: false,
			color: '#fff',
			backgroundColor: '#f77c56',
			// isLoading: loadingHubSpot,
			disabled: isHubSpotIntegrated,
			onClick: () => {
				hubSpotAuth();
			},
		},
		{
			id: 'quickbooks',
			buttonText: isQuickbooksIntegrated
				? 'Connected'
				: 'Connect to Quickbooks',
			logo: '/assets/images/quickbooks-logo.png',
			ghost: false,
			color: '#fff',
			backgroundColor: '#2b9b1b',
			isLoading: loadingQuickbooks,
			disabled: isQuickbooksIntegrated,
			onClick: () => {
				qboAuth();
			},
		},
	];
	const setIsAPIKeyModalClose = () => {
		setIsAPIKeyModalOpen(false);
		getConnections();
		// setLoadingHubSpot(false);
	};

	useEffect(() => {
		getConnections();
	}, []);

	// Render the component.
	return (
		<>
			{isAPIKeyModalOpen ? (
				<APIKeyModal
					keyModalVisible={isAPIKeyModalOpen}
					setKeyModalVisible={setIsAPIKeyModalClose}
				/>
			) : null}
			{connectionData && connectionData.length === 2 ? (
				<ConnectionTable />
			) : (
				<Cards cards={myCards} />
			)}
		</>
	);
}
