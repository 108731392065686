import React, { useState } from 'react';
import { Tabs } from 'antd';
import './index.scss';
import CommissionConfiguration from 'components/Settings/CommissionConfiguration';
import NewProduct from 'components/Settings/NewProduct';
import SpecialProduct from 'components/Settings/SpecialProduct';
export const Commission = () => {
	const [isAdmin, setIsAdmin] = useState('global-constants');

	const items = [
		{
			key: 'global-constants',
			label: 'Global Constants',
			children: (
				<div>
					<CommissionConfiguration />
				</div>
			),
		},
		{
			key: 'new-product',
			label: 'New Products',
			children: <NewProduct />,
		},
		{
			key: 'special-products',
			label: 'Special/Intermediate Products',
			children: <SpecialProduct />,
		},
	];

	return (
		<div>
			<Tabs
				defaultActiveKey="global-constants"
				items={items}
				activeKey={isAdmin}
				// tabBarStyle={{
				// 	paddingLeft: '20px',
				// 	fontSize: '18px',
				// }}
				onChange={(key) => {
					setIsAdmin(key);
				}}
				style={{ fontSize: '18px' }}
			/>
		</div>
	);
};
