import { Switch } from 'antd';
import { RoleContext } from 'components/Global/AuthLayout';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ViewByCustomer from '../ViewByCustomers';
import ViewBySales from '../ViewBySales';
import styles from './index.module.scss';

interface Props {
	changeSalesRep: (id: string) => void;
	selectedSalesRep: string | null;
	changeSwitch: any;
	isViewBySalesRep: boolean;
	totalRecords: any;
	currentPage: any;
	pageSize: any;
	paginationChangeHandler: any;
	pageSizeHandler: any;
	isLoading:boolean;
}

const Customers = (props: Props) => {
	const {
		changeSalesRep,
		selectedSalesRep,
		isViewBySalesRep,
		changeSwitch,
		totalRecords,
		currentPage,
		pageSize,
		paginationChangeHandler,
		pageSizeHandler,
		isLoading,
	} = props;

	const allSalesRep = useSelector((state: any) => state.salesReps.data);

	// const [isViewBySalesRep, setIsViewBySalesRep] = useState(true);
	// const [viewName, setViewName] = useState('View By Sales Rep');

	const changeView = (e: any) => {
		// if (e === true) {
		// 	setViewName('View By Sales Rep');
		// } else {
		// 	setViewName('View By Customers');
		// }
		changeSwitch(e);
	};

	const roleContext = useContext(RoleContext);

	useEffect(() => {
		if (selectedSalesRep == null && allSalesRep.length > 0) {
			changeSalesRep(allSalesRep[0]?.id);
		}
	}, [selectedSalesRep, allSalesRep]);

	return (
		<div>
			{roleContext.role === 'ADMIN' && (
				<div className={styles['switch-div']}>
					<span>View By Customers</span>
					<Switch onChange={changeView} checked={isViewBySalesRep} />
					<span>View By Sales Rep</span>
					{/* <span>{viewName}</span> */}
				</div>
			)}
			<div>
				{isViewBySalesRep ? (
					<ViewBySales
						changeSalesRep={changeSalesRep}
						selectedSalesRep={selectedSalesRep}
						totalRecords={totalRecords}
						currentPage={currentPage}
						pageSize={pageSize}
						paginationChangeHandler={paginationChangeHandler}
						pageSizeHandler={pageSizeHandler}
						isLoading={isLoading}
					/>
				) : (
					<ViewByCustomer />
				)}
			</div>
		</div>
	);
};

export default Customers;
