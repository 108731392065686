import { RoleContext } from 'components/Global/AuthLayout';
import { SalesOrdersReport } from 'components/SalesOrders';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SalesOrders = () => {
	const navigate = useNavigate();

	const roleContext = useContext(RoleContext);

	useEffect(() => {
		if (roleContext.role === 'SALES_REP') {
			navigate('/');
		}
	}, [roleContext.role]);

	return (
		<div>
			<h1>Sales Order And Commission</h1>
			<SalesOrdersReport />
		</div>
	);
};

export default SalesOrders;
