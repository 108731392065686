import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import styles from './index.module.scss';
import { Tooltip } from 'antd';
interface Props {
	text: string;
	handleRefresh: () => void;
	isRefreshing: boolean;
	tooltip: string;
	icon: any;
}
const Sync = (props: Props) => {
	const { text, handleRefresh, isRefreshing, tooltip, icon } = props;
	return (
		<Tooltip title={tooltip} placement="bottom">
			<div
				className={`${styles['sync']}  text-color ${
					isRefreshing ? 'pointer-event-none' : 'pointer'
				}`}
				onClick={() => handleRefresh()}
			>
				<div>{isRefreshing ? <LoadingOutlined /> : icon}</div>
				<div>{text}</div>
			</div>
		</Tooltip>
	);
};

export default Sync;
