import Buttons from 'components/Global/Buttons';
import { useEffect, useState } from 'react';
import { AddSvg } from 'utils/svgs';
import NewProductTable from './Table';
import NewProductModal from './Modal';
import styles from './index.module.scss';
import { getApi } from 'apis';
import { toastText } from 'utils/utils';

const NewProduct = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [products, setProducts] = useState([]);
	const [newProducts, setNewProducts] = useState([]);

	const [salesReps, setSalesReps] = useState([]);

	const [buttons] = useState([
		{
			text: 'Add New Product',
			isLoading: false,
			className: 'primary-button',
			fontSize: '1.7rem',
			minWidth: '20rem',
			minHeight: '4rem',
			icon: <AddSvg />,
			styles: {},
			isSubmit: false,
			onclick: async () => {
				getProducts();
			},
		},
	]);

	const handleSubmit = () => {
		console.log('Submit');
	};

	const getProducts = async () => {
		try {
			const response = await getApi('/products');
			setProducts(response.data.data);
			setIsModalOpen(true);
		} catch (error: any) {
			toastText('Something went wrong', 'error');
		}
	};

	const getNewProducts = async () => {
		try {
			const response = await getApi('/products/new');
			setNewProducts(response.data.data);
		} catch (error: any) {
			toastText('Something went wrong', 'error');
		}
	};

	const getSalesReps = async () => {
		try {
			const response = await getApi('/sales-rep');
			setSalesReps(response.data.data);
		} catch (error: any) {
			toastText('Something went wrong', 'error');
		}
	};

	const changeNewProducts = (data: any) => {
		setNewProducts(data);
	};

	useEffect(() => {
		getNewProducts();
		getSalesReps();
	}, []);

	return (
		<div className={styles['new-product-layout']}>
			<div className={styles['new-product-header']}>
				<Buttons buttons={buttons} gap="large" />
			</div>
			<div>
				<NewProductTable
					newProducts={newProducts}
					getSalesRep={salesReps}
					changeNewProducts={changeNewProducts}
					getNewProducts={getNewProducts}
				/>
			</div>

			{isModalOpen && (
				<NewProductModal
					handleOk={handleSubmit}
					isOpen={isModalOpen}
					handleCancel={() => setIsModalOpen(false)}
					products={products}
					getNewProducts={getNewProducts}
				/>
			)}
		</div>
	);
};

export default NewProduct;
