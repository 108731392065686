import { createSlice } from '@reduxjs/toolkit';
import {
	getAllSalesRepsAction,
	getSalesRepDetailsAction,
	inviteSalesRepsAction,
} from '../actions/salesRepsAction';
// import { toastText } from 'utils/utils';

const initialState: any = {
	data: [],
	count: 0,
	customerCount: 0,
	isLoading: true,
	fistTimeFetchLoading: true,
	error: null,
	details: null,
	detailsLoading: false,
	detailsError: null,
};

const SalesRepsSlice = createSlice({
	name: 'salesReps',
	initialState,
	reducers: {
		updateLoadingHandler: (state) => {
			state.isLoading = false;
		},
		clearRedux: () => {
			return {
				...initialState,
			};
		},
	},
	extraReducers: (builder) => {
		// Invite New salesReps
		builder.addCase(inviteSalesRepsAction.pending, (state) => {
			state.isLoading = false;
			// for loader at save button
			// state.isAddUserLoading = true;
			state.error = null;
		});
		builder.addCase(inviteSalesRepsAction.fulfilled, (state, action) => {
			state.data = [...state.data, action.payload.data];
			state.count = state.count + 1;
			// state.isLoading = false;
			// for loader at save button
			// state.isAddUserLoading = false;
		});
		builder.addCase(
			inviteSalesRepsAction.rejected,
			(state, action: any) => {
				state.error = action.payload;
				// if (action?.payload?.responseStatus !== 401) {
				// 	toastText(action?.payload?.message, 'error');
				// }
				// state.isLoading = false;
				// for loader at save button
				// state.isAddUserLoading = false;
			}
		);

		builder.addCase(getAllSalesRepsAction.pending, (state) => {
			state.isLoading = true;
			state.error = null;
			state.data = [];
		});
		builder.addCase(getAllSalesRepsAction.fulfilled, (state, action) => {
			state.isLoading = false;
			state.error = null;
			state.data = action.payload.data;
		});
		builder.addCase(getAllSalesRepsAction.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
			state.data = [];
		});

		builder.addCase(getSalesRepDetailsAction.pending, (state) => {
			state.detailsLoading = true;
			state.detailsError = null;
			state.details = null;
		});
		builder.addCase(getSalesRepDetailsAction.fulfilled, (state, action) => {
			state.detailsLoading = true;
			state.detailsError = null;
			state.details = action.payload.data;
			state.customerCount = action.payload.total;
		});
		builder.addCase(getSalesRepDetailsAction.rejected, (state, action) => {
			state.detailsLoading = false;
			state.detailsError = action.payload;
			state.details = null;
		});
	},
});

export default SalesRepsSlice;
export const { updateLoadingHandler, clearRedux } = SalesRepsSlice.actions;
