import { CloseOutlined } from '@ant-design/icons';
import { Checkbox, InputNumber, Modal, Popconfirm, Row, Spin } from 'antd';
import { getApi, postApi } from 'apis';
import Buttons from 'components/Global/Buttons';
import { useEffect, useState } from 'react';
import { toastText } from 'utils/utils';
import styles from './index.module.scss';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { getAllSalesRepsAction } from '../../../../redux/actions/salesRepsAction';

interface Props {
	isOpen: boolean;
	handleCancel: () => void;
	salesRepId: string | null;
	periodCategory: string | null;
	changeCategory: (value: string | null) => void;
}

const initialState = {
	salary: 0,
	insideCommissionPlan: {
		isActive: false,
		value: 0,
	},
	outsideCommissionPlan: {
		isActive: false,
		value: 0,
	},
	specialCommissionPlan: {
		isActive: false,
		value: 0,
	},
	retentionCommissionPlan: {
		isActive: false,
		period: {
			'1st 4 weeks': 0,
			'4 weeks until 6 weeks': 0,
			'6 weeks until 8 weeks': 0,
			'8 weeks until 12 weeks': 0,
			'12 weeks until 24 weeks': 0,
			'24 weeks until 36 weeks': 0,
			'36 weeks until 52 weeks': 0,
			'More than 52 weeks': 0,
		},
	},
};

const EditPlanModal = (props: Props) => {
	const { isOpen, handleCancel, salesRepId, periodCategory, changeCategory } =
		props;

	const dispatch = useDispatch<AppDispatch>();

	const [commissionPlans, setCommissionPlans] = useState({
		salesRepId,
		...initialState,
	});

	const [isLoading, setIsLoading] = useState(false);

	const [showRetentionBody, setShowRetentionBody] = useState(
		commissionPlans?.retentionCommissionPlan?.isActive
	);
	const [openWarning, setOpenWarning] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				const response = await getApi(
					`/sales-rep/commission-plan?salesRepId=${salesRepId}`
				);
				if (response.data.data) {
					setCommissionPlans(response.data.data);
				} else {
					setCommissionPlans({
						salesRepId,
						...initialState,
					});
				}
			} catch (err) {
				console.log('ERR: ', err);
			} finally {
				setIsLoading(false);
			}
		};
		fetchData();
	}, [salesRepId]);

	useEffect(() => {
		if (commissionPlans) {
			setShowRetentionBody(
				commissionPlans?.retentionCommissionPlan?.isActive
			);
		}
	}, [commissionPlans]);

	const showPopconfirm = () => {
		setOpenWarning(true);
	};

	const handleWarningOk = () => {
		setConfirmLoading(true);
		handleSubmit();
	};

	const handleWarningCancel = () => {
		setOpenWarning(false);
	};

	const myButtons = [
		{
			text: 'Save',
			isLoading: false,
			className: 'primary-button',
			fontSize: '1.7rem',
			minWidth: '12rem',
			minHeight: '4rem',
			styles: {},
			isSubmit: true,
			onclick: () => {
				showPopconfirm();
			},
		},
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.7rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: false,
			styles: {},
			onclick: () => {
				handleCancel();
			},
		},
	];

	const handleSubmit = async () => {
		const data = {
			salesRepId,
			floorSalary: commissionPlans.salary,
			insideCommissionPlan: commissionPlans.insideCommissionPlan,
			outsideCommissionPlan: commissionPlans.outsideCommissionPlan,
			specialCommissionPlan: commissionPlans.specialCommissionPlan,
			retentionCommissionPlan: commissionPlans.retentionCommissionPlan,
		};
		try {
			const response = await postApi('/sales-rep/commission-plan', data);
			toastText(response.data.message, 'success');
			handleCancel();
			dispatch(getAllSalesRepsAction());
		} catch (error: any) {
			toastText(error.response.data?.message, 'error');
		} finally {
			changeCategory(null);
		}
	};

	const handleInsidePlanChange = (checked: boolean, value: number) => {
		setCommissionPlans((prevPlans) => ({
			...prevPlans,
			insideCommissionPlan: { isActive: checked, value },
		}));
	};

	const handleOutsidePlanChange = (checked: boolean, value: number) => {
		setCommissionPlans((prevPlans) => ({
			...prevPlans,
			outsideCommissionPlan: { isActive: checked, value },
		}));
	};

	const handleSpecialPlanChange = (checked: boolean, value: number) => {
		setCommissionPlans((prevPlans) => ({
			...prevPlans,
			specialCommissionPlan: { isActive: checked, value },
		}));
	};

	const handleRetentionPlanChange = (period: string, value: number) => {
		setCommissionPlans((prevPlans) => ({
			...prevPlans,
			retentionCommissionPlan: {
				...prevPlans.retentionCommissionPlan,
				period: {
					...prevPlans.retentionCommissionPlan.period,
					[period]: value,
				},
			},
		}));
	};

	const handleFloorSalary = (value: number) => {
		setCommissionPlans((prevPlans) => ({
			...prevPlans,
			salary: value,
		}));
	};
	return (
		<div>
			<Modal
				open={isOpen}
				// onOk={handleSubmit}
				closable={false}
				// confirmLoading={confirmLoading}
				onCancel={handleCancel}
				footer={() => (
					<div className={styles['modal-footer']}>
						<Popconfirm
							title="Changes to commission plan may impact commission calculations."
							open={openWarning}
							onConfirm={handleWarningOk}
							okButtonProps={{ loading: confirmLoading }}
							onCancel={handleWarningCancel}
							placement="topLeft"
						>
							<Buttons buttons={myButtons} gap="large" />
						</Popconfirm>
					</div>
				)}
				width={800}
			>
				<Row className={styles['modal-header']}>
					<p className={styles['modal-header_title']}>
						Select Commission Plan
					</p>
					<div className="pointer">
						<CloseOutlined
							className={styles['modal-header_close']}
							onClick={handleCancel}
							style={{
								fontSize: '2rem',
								fontWeight: '500',
							}}
						/>
					</div>
				</Row>
				{isLoading ? (
					<div
						style={{
							width: '100%',
							minHeight: '200px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Spin />
					</div>
				) : (
					// <img src={`/assets/gifs/loading-black.gif`} height={60} />
					<div className={styles['modal-body']}>
						<div className={styles['modal-body_salary']}>
							<p>Floor Salary</p>
							<InputNumber
								min={0}
								type="number"
								value={commissionPlans.salary}
								onChange={(value: any) =>
									handleFloorSalary(value)
								}
								parser={(value: any) => {
									const parsedValue = parseFloat(
										value || '0'
									);
									const maxLength = 6;
									if (value.length > maxLength) {
										// Truncate the input value to maximum length
										return value.slice(0, maxLength);
									}
									return isNaN(parsedValue) ? 0 : parsedValue;
								}}
							/>
						</div>
						<div className={styles['modal-body_plan']}>
							<Checkbox
								checked={
									commissionPlans.insideCommissionPlan
										.isActive
								}
								onChange={(e) =>
									handleInsidePlanChange(
										e.target.checked,
										commissionPlans.insideCommissionPlan
											.value
									)
								}
							>
								Inside Sales Commission Plan
							</Checkbox>
							<div className={styles['modal-body_plan-gross']}>
								<InputNumber
									min={0}
									max={100}
									type="number"
									value={
										commissionPlans.insideCommissionPlan
											.value
									}
									suffix="%"
									onChange={(value: any) =>
										handleInsidePlanChange(
											commissionPlans.insideCommissionPlan
												.isActive,
											value
										)
									}
									parser={(value) => {
										const parsedValue = parseFloat(
											value || '0'
										);
										return isNaN(parsedValue)
											? 0
											: parsedValue;
									}}
								/>
								<span> of Adjusted Gross Profit</span>
							</div>
						</div>
						<div className={styles['modal-body_plan']}>
							<Checkbox
								checked={
									commissionPlans.outsideCommissionPlan
										.isActive
								}
								onChange={(e) =>
									handleOutsidePlanChange(
										e.target.checked,
										commissionPlans.outsideCommissionPlan
											.value
									)
								}
							>
								Outside Commission Plan
							</Checkbox>
							<div className={styles['modal-body_plan-gross']}>
								<InputNumber
									min={0}
									max={100}
									type="number"
									value={
										commissionPlans.outsideCommissionPlan
											.value
									}
									suffix="%"
									onChange={(value: any) =>
										handleOutsidePlanChange(
											commissionPlans
												.outsideCommissionPlan.isActive,
											value
										)
									}
									parser={(value) => {
										const parsedValue = parseFloat(
											value || '0'
										);
										return isNaN(parsedValue)
											? 0
											: parsedValue;
									}}
								/>
								<span> of Adjusted Gross Profit</span>
							</div>
						</div>
						<div className={styles['modal-body_plan']}>
							<Checkbox
								checked={
									commissionPlans.specialCommissionPlan
										.isActive
								}
								onChange={(e) =>
									handleSpecialPlanChange(
										e.target.checked,
										commissionPlans.specialCommissionPlan
											.value
									)
								}
							>
								Special / Intermediate Commission
							</Checkbox>
							<div className={styles['modal-body_plan-gross']}>
								<InputNumber
									min={0}
									max={100}
									type="number"
									value={
										commissionPlans.specialCommissionPlan
											.value
									}
									suffix="%"
									onChange={(value: any) =>
										handleSpecialPlanChange(
											commissionPlans
												.specialCommissionPlan.isActive,
											value
										)
									}
									parser={(value) => {
										const parsedValue = parseFloat(
											value || '0'
										);
										return isNaN(parsedValue)
											? 0
											: parsedValue;
									}}
								/>
								<span> of Adjusted Gross Profit</span>
							</div>
						</div>
						<div className={styles['modal-body_plan-new']}>
							<Checkbox
								checked={
									commissionPlans.retentionCommissionPlan
										.isActive
								}
								onChange={(e) => {
									setShowRetentionBody(e.target.checked);
									setCommissionPlans((prev) => {
										return {
											...prev,
											retentionCommissionPlan: {
												...prev.retentionCommissionPlan,
												isActive: e.target.checked,
											},
										};
									});
								}}
							>
								Retention / Relationship Plan
							</Checkbox>
							<div
								className={styles['modal-body_plan-gross-new']}
							>
								<div
									className={
										styles['modal-body_plan-retention']
									}
								>
									<div> </div>
									<div>Variable Based On Time</div>
								</div>
								{showRetentionBody && (
									<div>
										{Object.entries(
											commissionPlans
												.retentionCommissionPlan.period
										)
											.sort((a, b) => {
												const order = [
													'1st 4 weeks',
													'4 weeks until 6 weeks',
													'6 weeks until 8 weeks',
													'8 weeks until 12 weeks',
													'12 weeks until 24 weeks',
													'24 weeks until 36 weeks',
													'36 weeks until 52 weeks',
													'More than 52 weeks',
												];
												return (
													order.indexOf(a[0]) -
													order.indexOf(b[0])
												);
											})
											.map(([period, value]) => (
												<div
													key={period}
													className={`${
														styles[
															'modal-body_plan-retention'
														]
													} ${
														period ===
														periodCategory
															? styles[
																	'modal-body_plan-retention--period'
															  ]
															: ''
													}`}
												>
													<div>{period}</div>
													<InputNumber
														min={0}
														max={100}
														value={value}
														type="number"
														suffix="%"
														onChange={(
															newValue: any
														) =>
															handleRetentionPlanChange(
																period,
																newValue
															)
														}
														parser={(value) => {
															const parsedValue =
																parseFloat(
																	value || '0'
																);
															return isNaN(
																parsedValue
															)
																? 0
																: parsedValue;
														}}
													/>
												</div>
											))}
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			</Modal>
		</div>
	);
};

export default EditPlanModal;
