import { Table } from 'antd';
import ConfirmDelete from 'components/Global/confirmDeleteModel';
import { useState } from 'react';
import styles from './index.module.scss';
import { putApi } from 'apis';
import { toastText } from 'utils/utils';
const { Column } = Table;
interface Props {
	specialProducts: any;
	getSpecialProducts: any;
}
const SpecialProductTable = (props: Props) => {
	const { specialProducts, getSpecialProducts } = props;

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [deleteData, setDeleteData] = useState<any>(null);
	const [isLoading, setIsLoading] = useState(false);
	// Delete User
	const deleteHandler = async () => {
		setIsLoading(true);
		if (deleteData) {
			try {
				setIsLoading(true);
				const response = await putApi('/products/special', {
					productId: deleteData,
				});
				getSpecialProducts();
				toastText(response.data.message, 'success');
			} catch (error: any) {
				toastText(error.response.data.message, 'error');
			} finally {
				setIsLoading(false);
				setIsDeleteModalOpen(false);
			}
		}
	};
	const deleteModalOpen = (data: any) => {
		setIsDeleteModalOpen(true);
		setDeleteData(data);
	};
	return (
		<div>
			<Table
				dataSource={specialProducts}
				pagination={false}
				scroll={{ y: 'calc(100vh - 370px)' }}
				rowKey={(record) => record.id}
			>
				<Column
					title="No."
					dataIndex="sr"
					key="sr"
					className="bg-white"
					render={(value, data, index) => {
						return `${index + 1}`;
					}}
					width={250}
				/>
				<Column
					title="Product Name"
					dataIndex="name"
					key="name"
					className="bg-white"
					render={(value, data: any) => {
						return <p>{value ? value : data?.name}</p>;
					}}
				/>

				<Column
					title="Product/SKUs"
					dataIndex="sku"
					key="sku"
					className="bg-white"
					render={(value, data: any) => {
						return <p>{value ? value : data?.name}</p>;
					}}
				/>

				<Column
					title="Action"
					dataIndex="action"
					key="action"
					className="bg-white"
					render={(value: string, data: any) => {
						return (
							<div>
								<span
									onClick={() => deleteModalOpen(data.id)}
									className={styles['special-product-remove']}
								>
									Remove
								</span>
							</div>
						);
					}}
					width={250}
				/>
			</Table>
			{isDeleteModalOpen && (
				<ConfirmDelete
					handleCancel={() => {
						setIsDeleteModalOpen(false);
						setDeleteData(null);
					}}
					handleOk={() => {
						setIsDeleteModalOpen(false);
						setDeleteData(null);
					}}
					isModalOpen={isDeleteModalOpen}
					deleteHandler={deleteHandler}
					isLoading={isLoading}
				/>
			)}
		</div>
	);
};

export default SpecialProductTable;
