import toast from 'react-hot-toast';

export const toastText = (message: string, type: string) => {
	switch (type) {
		case 'success':
			toast.success(message, {
				style: {
					fontSize: '16px',
				},
			});

			break;

		case 'error':
			toast.error(message, {
				style: {
					fontSize: '16px',
				},
			});
			break;
	}
};

export function invalidText(value: string | number | null | undefined) {
	return (
		value == null ||
		value == undefined ||
		value.toString().trim().length == 0
	);
}

export function base64ToBlob(base64: string, mime: string) {
	const byteCharacters = atob(base64);
	const arrayBuffer = new ArrayBuffer(byteCharacters.length);
	const uint8Array = new Uint8Array(arrayBuffer);
	for (let i = 0; i < byteCharacters.length; i++) {
		uint8Array[i] = byteCharacters.charCodeAt(i);
	}
	return new Blob([uint8Array], { type: mime });
}

export const deepCopy = (obj: any) => {
	return JSON.parse(JSON.stringify(obj));
};

export const formatPhoneNumber = (phoneNumber: string) => {
	// Remove all non-numeric characters from the input
	const cleanedNumber = phoneNumber.replace(/\D/g, '');

	// Define the phone number format (e.g., "(XXX) XXX-XXXX")
	const format = '($1) $2-$3';

	// Apply the mask to the cleaned number using a regular expression
	const maskedNumber = cleanedNumber.replace(/(\d{3})(\d{3})(\d{4})/, format);

	return maskedNumber;
};

export const phoneNumberFormatHandler = (phoneNumber: any = '') => {
	const inputPhoneNumber = phoneNumber?.replace(/\D/g, ''); // Remove non-digit characters
	let formattedPhoneNumber = '';

	if (inputPhoneNumber.length > 0) {
		formattedPhoneNumber = `(${inputPhoneNumber.slice(0, 3)}`;

		if (inputPhoneNumber.length >= 4) {
			formattedPhoneNumber += `) ${inputPhoneNumber.slice(3, 6)}`;
		}

		if (inputPhoneNumber.length >= 7) {
			formattedPhoneNumber += `-${inputPhoneNumber.slice(6, 10)}`;
		}
	}

	return formattedPhoneNumber;
};

export const getPeriodCategory = (hubspotDate: any) => {
	const today: any = new Date();

	const millisecondsPerWeek = 1000 * 60 * 60 * 24 * 7;

	const timeDifferenceInWeeks = Math.round(
		(today - hubspotDate) / millisecondsPerWeek
	);

	let category = '';

	if (timeDifferenceInWeeks <= 4) {
		category = '1st 4 weeks';
	} else if (timeDifferenceInWeeks <= 6) {
		category = '4 weeks until 6 weeks';
	} else if (timeDifferenceInWeeks <= 8) {
		category = '6 weeks until 8 weeks';
	} else if (timeDifferenceInWeeks <= 12) {
		category = '8 weeks until 12 weeks';
	} else if (timeDifferenceInWeeks <= 24) {
		category = '12 weeks until 24 weeks';
	} else if (timeDifferenceInWeeks <= 36) {
		category = '24 weeks until 36 weeks';
	} else if (timeDifferenceInWeeks <= 52) {
		category = '36 weeks until 52 weeks';
	} else {
		category = 'More than 52 weeks';
	}
	return category;
};

export const addCommasToNumber = (number: any) => {
	if (number) {
		const parts = number?.toString().split('.');
		if (parts && parts.length > 0) {
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			return parts.join('.');
		}
	} else {
		return '0';
	}
};
