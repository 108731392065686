import { Col, Image, Row, Tooltip } from 'antd';
import UserProfileModal from 'components/Profile';
import { useContext, useState } from 'react';
import UserNameBox from '../UserNameBox';
import styles from './index.module.scss';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAction } from '../../../redux/actions/logoutAction';
import { toastText } from 'utils/utils';
import { RoleContext } from '../AuthLayout';
import { clearProfileRedux } from '../../../redux/slices/profileSlice';

const Header = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const roleContext = useContext(RoleContext);

	const [isProfileModalOpen, setProfileModalOpen] = useState<boolean>(false);
	const [isLogoutLoading, setIsLogoutLoading] = useState(false);

	const { data: userData } = useSelector((state: any) => state?.userProfile);

	const logoutData = {
		email: userData?.email,
		accessToken: userData?.accessToken,
	};

	// Logout Handler
	const logoutHandler = async () => {
		setIsLogoutLoading(true);
		setIsLogoutLoading(false);
		try {
			const data = { logoutData };
			const response = await dispatch(logoutAction(data) as any);

			dispatch(clearProfileRedux());
			roleContext.roleHandler(null);
			toastText(response?.payload?.message, 'success');
			navigate('/login');
		} catch (error: any) {
			toastText(error.response.data?.message, 'error');
			localStorage.clear();
		} finally {
			localStorage.removeItem('accessToken');
		}
	};

	// My Profile Handler
	const myProfileHandler = () => {
		setProfileModalOpen(true);
	};

	const profileCancel = () => {
		setProfileModalOpen(false);
	};

	const handleNavigatePermissionWise = (currentPath: string) => {
		const targetPath =
			currentPath === '/settings/integrations' ||
			currentPath === '/settings/commissions'
				? '/'
				: '/settings/integrations';
		navigate(targetPath);
	};

	// JSX
	return (
		<>
			<header className={styles['header']}>
				<Row
					className={styles['header__wrapper']}
					align={'middle'}
					justify={'space-between'}
				>
					<Col className={styles['header__details-left']}>
						<div className={styles['header__details-left--logo']}>
							<Image
								src="/assets/images/ees-logo.png"
								preview={false}
								alt="group"
							/>
						</div>
					</Col>
					<Col className={styles['header__details-right']}>
						{roleContext.role === 'ADMIN' && (
							<Tooltip placement="bottom" title={`Settings`}>
								<SettingOutlined
									style={{ fontSize: 24 }}
									onClick={() =>
										handleNavigatePermissionWise(
											window.location.pathname
										)
									}
								/>
							</Tooltip>
						)}

						<Tooltip placement="bottom" title={`Profile`}>
							<div
								className={
									styles['header__details-right--user']
								}
								onClick={myProfileHandler}
							>
								<div
									className={
										styles[
											'header__details-right--user-logo'
										]
									}
								>
									<UserNameBox
										name={`${userData?.firstName} ${userData?.lastName}`}
									/>
								</div>

								<div
									className={
										styles[
											'header__details-right--user-details'
										]
									}
								>
									<p
										className={
											styles[
												'header__details-right--user-name'
											]
										}
									>
										{userData?.firstName}{' '}
										{userData?.lastName}
									</p>
									<p
										className={
											styles[
												'header__details-right--user-profile'
											]
										}
									>
										My Profile
									</p>
								</div>
							</div>
						</Tooltip>

						<div
							className={
								styles['header__details-right--user-logout']
							}
						>
							<Tooltip placement="bottom" title={`Logout`}>
								<LogoutOutlined
									style={{
										cursor: isLogoutLoading
											? 'not-allowed'
											: 'pointer',
										pointerEvents: isLogoutLoading
											? 'none'
											: 'auto',
										fontSize: 23,
										color: '#727272',
									}}
									onClick={logoutHandler}
								/>
							</Tooltip>
						</div>
					</Col>
				</Row>
			</header>

			{isProfileModalOpen && (
				<UserProfileModal
					isProfileModalOpen={isProfileModalOpen}
					handleCancel={profileCancel}
				/>
			)}
		</>
	);
};

export default Header;
