import { AuthLayout } from 'components/Global/AuthLayout';
import CallbackComponent from 'components/Settings/CallbackComponent';
import GlobalLayout from 'layouts/Global';
import { ForgotPassword, Login, ResetPassword } from 'pages';
import AccessDeniedPage from 'pages/AccessDeniedPage';
import PageNotFoundPage from 'pages/PageNotFound';
import Reports from 'pages/Reports';
import SalesOrders from 'pages/SalesOrders';
import { SalesReps } from 'pages/SalesReps';
import { Commission } from 'pages/Settings/Commission';
import Integration from 'pages/Settings/Integration';
import Settings from 'pages/Settings/Settings';
import { createBrowserRouter } from 'react-router-dom';

const router = createBrowserRouter([
	{
		element: <AuthLayout />,
		children: [
			{
				path: '/',
				element: <GlobalLayout />,
				children: [
					{
						index: true,
						element: <SalesReps />,
					},
					{
						path: '/sales-orders',
						element: <SalesOrders />,
					},
					{
						path: '/reports',
						element: <Reports />,
					},
				],
			},
			{
				path: '/settings',
				element: <Settings />,
				children: [
					{
						path: 'integrations',
						element: <Integration />,
					},
					{
						path: 'commissions',
						element: <Commission />,
					},
				],
			},
			{
				element: <Login />,
				path: '/login',
			},
			{
				path: '/reset-password',
				element: <ResetPassword />,
			},
			{
				path: '/set-password',
				element: <ResetPassword />,
			},
			{
				path: '/forgot-password',
				element: <ForgotPassword />,
			},
			{
				path: '/access-denied',
				element: <AccessDeniedPage />,
			},
			{
				path: '/qbo-callback',
				element: <CallbackComponent />,
			},
		],
	},
	{
		path: '*',
		element: <PageNotFoundPage />,
	},
]);

export default router;
